import React, { useState, useEffect, useRef } from "react";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import {
  Icon,
  Tag,
  Dropdown,
  Checkbox,
  Accordion,
  useNav,
} from "@intility/bifrost-react";

import { useLocation } from "../contexts/locationContext";
import { useSave } from "contexts/saveContext";

const FloorMenu = () => {
  const {
    locations,
    selectedLocation,
    selectedFloorPart,
    setLocation,
    setFloorPart,
    selectedFloor,
  } = useLocation();

  const { editing } = useSave();

  const [scrollRef, setScrollRef] = useState();
  const floorMenuRef = useRef();
  const [showLocationList, setShowLocationList] = useState(false);
  const [showLocationListAccordion, setShowLocationListAccordion] = useState(
    false
  );
  const [accordionOpen, setAccordionOpen] = useState(false);

  const { setMobileOpen } = useNav();

  // Scroll floors to show active floor
  useEffect(() => {
    if (!selectedFloor || !scrollRef || !floorMenuRef) return;
    const totalHeight = floorMenuRef.current.clientHeight;
    const diff = totalHeight - (selectedFloor.floorNumber * 32 + 15);

    scrollRef.scrollTo(0, diff);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef]);

  // Scroll floors when changing locations
  useEffect(() => {
    if (!selectedLocation || !floorMenuRef || !scrollRef) return;
    const totalHeight = floorMenuRef.current.clientHeight;
    scrollRef.scrollTo(0, totalHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const getFloors = () => {
    let sortedFloors;
    if (selectedLocation.name === "Satellite offices") {
      sortedFloors = selectedLocation.floors.slice();
      sortedFloors.sort((a, b) =>
        a.floorParts[0].name > b.floorParts[0].name
          ? 1
          : b.floorParts[0].name > a.floorParts[0].name
          ? -1
          : 0
      );
    } else {
      sortedFloors = selectedLocation.floors.slice();
      sortedFloors.sort(sortFloorsDescending);
    }

    const floors = sortedFloors.map((floor) => {
      if (floor.floorParts.length === 1) {
        return (
          <div key={floor.id} className="floor-container">
            {getFloorParts(floor)}
          </div>
        );
      } else {
        return (
          <div key={floor.id} className="floor-container">
            <p className="floor-number">{floor.floorNumber}.</p>
            {getFloorParts(floor)}
          </div>
        );
      }
    });

    return floors;
  };

  const getFloorParts = (floor) => {
    return floor.floorParts.map((floorPart) => (
      <Tag
        key={floorPart.id}
        active={selectedFloorPart.id === floorPart.id}
        onClick={() => {
          setFloorPart(floorPart);
          if (!editing) {
            setMobileOpen(false);
          }
        }}
      >
        {floorPart.name ? floorPart.name : `${floor.floorNumber}. Floor`}
      </Tag>
    ));
  };

  const sortFloorsDescending = (a, b) => {
    return a.floorNumber > b.floorNumber
      ? -1
      : b.floorNumber > a.floorNumber
      ? 1
      : 0;
  };

  const locationsContent = () => {
    let sortedLocs = locations.filter((l) => l.name !== "Satellite offices");
    const other = locations.find((l) => l.name === "Satellite offices");
    if (other) {
      sortedLocs.push(other);
    }
    return (
      <div>
        {sortedLocs.map((location) => (
          <Checkbox
            key={location.name}
            label={location.name}
            type="radio"
            align="right"
            checked={location.id === selectedLocation.id}
            className="location"
            name="locationDropDown"
            onChange={() => {
              setShowLocationList(false);
              setShowLocationListAccordion(false);
              if (selectedLocation.id !== location.id) {
                setLocation(location);
              }
            }}
          />
        ))}
      </div>
    );
  };

  const getFloorMenu = (accordion) => {
    return (
      <>
        <Dropdown
          content={locationsContent()}
          className="locations-content"
          placement="bottom-start"
          visible={accordion ? showLocationListAccordion : showLocationList}
          onClickOutside={() => {
            setShowLocationList(false);
            setShowLocationListAccordion(false);
          }}
        >
          <div
            className="locations-dropdown"
            onClick={() => {
              accordion
                ? setShowLocationListAccordion(!showLocationListAccordion)
                : setShowLocationList(!showLocationList);
            }}
          >
            <h1>{selectedLocation.name}</h1>
            <Icon icon={faAngleDown} />
          </div>
        </Dropdown>
        <div
          className="scrollbar-container bf-scrollbar-small"
          ref={(ref) => {
            setScrollRef(ref);
          }}
        >
          <div className="floors-container" ref={floorMenuRef}>
            {getFloors()}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="floor-menu-container">
        {selectedLocation && getFloorMenu(false)}
      </div>
      {!editing && (
        <Accordion
          className={`floor-menu-accordion-container ${
            accordionOpen ? "open" : ""
          }`}
          variant="styled"
        >
          <Accordion.Item
            title="Select location or floor"
            active={accordionOpen}
            onClick={() => setAccordionOpen(!accordionOpen)}
            noPadding
          >
            {selectedLocation && getFloorMenu(true)}
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default FloorMenu;
