import React, { useState } from "react";

import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { Button, Modal, Input } from "@intility/bifrost-react";
import { useUser } from "@intility/react-msal-browser";

import { useSave } from "contexts/saveContext";
import "../../styles/css/dashboard.css";

const CreateDraftModal = ({ open, setOpen, saves }) => {
  const { createNewDraft } = useSave();
  const user = useUser();

  const [nameExists, setNameExists] = useState(false);
  const [draftName, setDraftName] = useState();
  const [submitted, setSubmitted] = useState(false);

  // Clicking create draft button
  const handleCreateDraft = () => {
    if (!draftName) {
      setSubmitted(true);
      return;
    }
    const exists = saves.find(
      (save) => save.name.toLowerCase() === draftName.toLowerCase()
    );
    if (exists) {
      setNameExists(true);
      return;
    }
    const id = user?.homeAccountId.split(".")[0];
    // New current save object
    const save = {
      name: draftName,
      createdByEmployeeId: id,
      lastEditedByEmployeeId: id,
      publicSave: false,
      beingEdited: true,
      desks: [],
      comments: [],
    };

    createNewDraft(save);
    setOpen(false);
  };

  return (
    <Modal
      className="add-new-draft-modal"
      header="Create draft"
      icon={faPlus}
      isOpen={open}
      onRequestClose={() => setOpen(false)}
    >
      <Input
        label="Draft name"
        placeholder="Eks. My draft"
        value={draftName ?? ""}
        autoFocus
        required
        onChange={(e) => {
          setDraftName(e.target.value);
          setNameExists(false);
          setSubmitted(false);
        }}
        {...(nameExists &&
          draftName && {
            state: "alert",
            feedback: "Draft name already exists",
          })}
        {...(!draftName &&
          submitted && {
            feedback: "Can't create draft without name",
          })}
      />
      <Modal.Footer>
        <div className="buttons-container">
          <Button
            onClick={() => {
              setDraftName(null);
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            {...((!draftName || (draftName && nameExists)) && {
              state: "inactive",
            })}
            variant="filled"
            onClick={() => handleCreateDraft()}
          >
            Create draft
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDraftModal;
