import React, { useState } from "react";

import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { TextArea, Button, Modal } from "@intility/bifrost-react";

import { useSave } from "../contexts/saveContext";
import "styles/css/card.css";

const CommentModal = ({
  showCommentModal,
  setShowCommentModal,
  employee,
  employeeComment,
}) => {
  const { currentSave, updateComments } = useSave();

  const [text, setText] = useState(employeeComment ? employeeComment.text : "");

  const cancelEditComment = () => {
    setShowCommentModal(false);

    setText(employeeComment ? employeeComment.text : "");
  };

  const saveComment = () => {
    let newCommentList = currentSave.comments.slice();
    let commentToUpdateIndex = currentSave.comments.findIndex(
      (c) => c.employeeId === employee.id
    );
    if (!text) {
      //delete comment
      newCommentList.splice(commentToUpdateIndex, 1);
    } else {
      //update or add comment
      let updatedComment = {
        employeeId: employee.id,
        text: text,
      };

      if (commentToUpdateIndex >= 0) {
        newCommentList[commentToUpdateIndex] = updatedComment;
      } else {
        newCommentList.push(updatedComment);
      }
    }
    updateComments(newCommentList);
    setShowCommentModal(false);
  };

  return (
    <Modal
      className="comment-modal"
      header={employeeComment ? "Edit comment" : "Add comment"}
      isOpen={showCommentModal}
      onRequestClose={() => cancelEditComment()}
      icon={faComment}
    >
      <TextArea
        className="comment-modal-textarea"
        label="Comment"
        hideLabel
        placeholder="Write a comment..."
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        autoFocus
      />
      <Modal.Footer>
        <div className="modal-btns-container">
          <Button onClick={() => cancelEditComment()}>Cancel</Button>
          <Button
            variant="filled"
            disabled={!employeeComment && !text}
            onClick={() => {
              saveComment();
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;
