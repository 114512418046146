import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import useSWR from "swr";
import ErrorMessage from "components/errorMessage";
import { useTheme } from "@intility/bifrost-react";
import { authorizedFetch } from "@intility/react-msal-browser";
import { useSave } from "./saveContext";

const LocationContext = createContext(null);

const useLocation = () => useContext(LocationContext);

const LocationProvider = (props) => {
  const { current } = useTheme();
  const { editing } = useSave();

  // Fetching locations
  const { data: locations, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/locations`,
    {
      revalidateOnFocus: editing ? false : true,
    }
  );

  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedFloor, setSelectedFloor] = useState();
  const [selectedFloorPart, setSelectedFloorPart] = useState();

  // Get floors
  const floors = useMemo(
    () => locations && locations.flatMap((location) => location.floors),
    [locations]
  );

  // Get floorparts
  const floorParts = useMemo(
    () => floors && floors.flatMap((floor) => floor.floorParts),
    [floors]
  );

  useEffect(() => {
    if (!locations || !floors || !floorParts) return;

    const localLocation = JSON.parse(localStorage.getItem("SeatingLocation"));
    const localFloorPart = JSON.parse(localStorage.getItem("SeatingFloorPart"));

    const localLocationExists = locations.find(
      (location) => location.id === localLocation?.id
    );
    const localFloorPartExists = floorParts?.find(
      (part) => part.id === localFloorPart?.id
    );

    // If there is localstorage, set localstorage value
    if (localLocationExists && localFloorPartExists) {
      handleLocalStorage(localLocation, localFloorPart);
    }
    // If there is no localstorage, set default
    else {
      setSelectedLocation(locations[0]);
      setSelectedFloor(locations[0]?.floors[0]);
      setSelectedFloorPart(locations[0]?.floors[0]?.floorParts[0]);

      localStorage.setItem("SeatingLocation", JSON.stringify(locations[0]));
      localStorage.setItem(
        "SeatingFloorPart",
        JSON.stringify(locations[0]?.floors[0]?.floorParts[0])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, floors, floorParts]);

  const handleLocalStorage = (localLocation, localFloorPart) => {
    setSelectedLocation(localLocation);
    const f = localFloorPart;
    const floor = floors.find((floor) => floor.id === f.floorId);
    setSelectedFloor(floor);
    setSelectedFloorPart(localFloorPart);
  };

  // Set location when changing location
  const setLocation = (location) => {
    setSelectedLocation(location);
    setSelectedFloorPart(location.floors[0].floorParts[0]);
    if (location) {
      localStorage.setItem("SeatingLocation", JSON.stringify(location));
    }
    if (location.floors[0] && location.floors[0].floorParts[0]) {
      localStorage.setItem(
        "SeatingFloorPart",
        JSON.stringify(location.floors[0].floorParts[0])
      );
    }
  };

  // Set selectedFloorPart when changing floor part
  const setFloorPart = (floorPart) => {
    let floorPartObj = floorPart;
    if (typeof floorPart !== "object") {
      const newFloorPartObj = floorParts.find((fp) => fp.id === floorPart);
      floorPartObj = newFloorPartObj;
    }

    const floor = floors.find((floor) => floor.id === floorPartObj.floorId);
    const location = locations.find(
      (location) => location.id === floor.locationId
    );
    if (location !== selectedLocation) {
      setSelectedLocation(location);
      localStorage.setItem("SeatingLocation", JSON.stringify(location));
    }
    setSelectedFloor(floor);
    setSelectedFloorPart(floorPartObj);
    localStorage.setItem("SeatingFloorPart", JSON.stringify(floorPartObj));
  };

  const [floorImage, setFloorImage] = useState(null);

  useEffect(() => {
    if (selectedFloorPart) {
      const getFloorPartImage = async () => {
        try {
          const url = `${process.env.REACT_APP_API_URL}${
            current === "dark"
              ? selectedFloorPart.imageDarkMode
              : selectedFloorPart.imageLightMode
          }`;

          const response = await authorizedFetch(url);
          if (!response.ok) {
            setFloorImage(null);
          }
          const data = await response.blob();
          const imageUrl = URL.createObjectURL(data);
          if (response.ok) {
            setFloorImage({ backgroundImage: `url(${imageUrl})` });
          }
          return imageUrl;
        } catch (e) {
          console.log(e);
        }
      };
      let promise = getFloorPartImage();
      return () => promise.then(URL.revokeObjectURL);
    }
  }, [selectedFloorPart, current]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <LocationContext.Provider
      value={{
        locations,
        selectedLocation,
        selectedFloor,
        selectedFloorPart,
        setLocation,
        setFloorPart,
        floorImage,
      }}
    >
      {props && props.children}
    </LocationContext.Provider>
  );
};

export { useLocation };
export default LocationProvider;
