import React, { useState, useEffect, useCallback } from "react";

import { Nav, Spinner, Modal, Button, Message } from "@intility/bifrost-react";
import GridLayout from "react-grid-layout";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "@intility/react-msal-browser";
import "../../node_modules/react-grid-layout/css/styles.css";

import { useEmployee } from "../contexts/employeeContext";
import { useLoading } from "contexts/loadingContext";
import { useLocation } from "../contexts/locationContext";
import { useRole } from "contexts/roleContext";
import { useSave } from "../contexts/saveContext";
import useBeforeUnload from "hooks/useBeforeUnload";
import useKeyPress from "../hooks/useKeyPress";

import Desk from "../components/desk";
import SeatingHeader from "../components/seatingHeader";
import SideMenu from "../components/sideMenu.js";
import ToolMenu from "../components/toolMenu.js";

import "../styles/css/desks.css";
import "../styles/css/reactGridLayout.css";

const gridProps = {
  deskHeight: 5,
  deskWidth: 3,
  nmbrOfRowsCols: 70,
  gridWidth: 1600,
  gridHeight: 900,
};

const ReactGridLayoutPage = () => {
  const { employees, departments, getEmployee } = useEmployee();

  const { draftId } = useParams();

  // FLOORS
  const {
    locations,
    selectedFloorPart,
    setFloorPart,
    selectedFloor,
    selectedLocation,
    floorImage,
  } = useLocation();

  const { isAdmin } = useRole();
  const { showSpinner } = useLoading();

  const {
    selectedSaveId,
    setSelectedSaveId,
    currentSave,
    updateDesks,
    placedEmployeesList,
    editing,
    removedEmployees,
    setRemovedEmployees,
  } = useSave();

  // Alert user leaving window in edit mode
  useBeforeUnload((e) => {
    if (editing) {
      e.preventDefault();
      e.returnValue =
        "Are you sure you want to leave the website? You might have unsaved changes.";
    }
  });

  const [showMessage, setShowMessage] = useState(false);

  // Set interval for showing message reminding user to save
  useEffect(() => {
    if (!editing) return;
    const interval = setInterval(() => {
      setShowMessage(true);
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, [editing]);

  useEffect(() => {
    if (!showMessage) return;
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 1000 * 60);
    return () => clearTimeout(timer);
  }, [showMessage]);

  useEffect(() => {
    if (!draftId) return;
    setSelectedSaveId(draftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  const findDesk = useCallback(
    (employeeId) => {
      let desk = currentSave.desks.find(
        (desk) => desk.employeeId === employeeId
      );
      if (desk) {
        setFloorPart(desk.floorPartId);
        setSelectedDeskId(desk.id);
      }
      if (desk?.floorPartId !== selectedFloorPart?.id) {
        setInitSelected(true);
      } else {
        setInitSelected(false);
      }
    },
    [currentSave.desks, setFloorPart]
  );

  const user = useUser();
  const [initSelected, setInitSelected] = useState(true);

  useEffect(() => {
    if (user) {
      const homeAccountIds = user.homeAccountId.split(".");
      findDesk(homeAccountIds[0]);
      setInitSelected(true);
    }
  }, [user, selectedSaveId]);

  // EMPLOYEE
  const [lockEmployees, setLockEmployees] = useState(false);
  const [draggingEmployee, setDraggingEmployee] = useState(false);
  const [draggingListEmployee, setDraggingListEmployee] = useState(false);
  const [
    replaceEmployeeConfirmationInfo,
    setReplaceEmployeeConfirmationInfo,
  ] = useState(null);
  const [
    employeeDepartmentFromDeletedDesk,
    setEmployeeDepartmentFromDeletedDesk,
  ] = useState("");

  const onDragEmployeeOver = (event) => {
    event.preventDefault();
  };

  const onDropEmployee = (event, desk) => {
    let employeeIdToMove = event.dataTransfer.getData("employeeId");
    let oldDesk = JSON.parse(event.dataTransfer.getData("currentDesk"));
    if (!desk.employeeId && !desk.dummyEmployee) {
      //desk is empty
      moveEmployee(desk, employeeIdToMove, oldDesk);
    } else if (desk.employeeId && oldDesk) {
      //desk is occupied by employee and employee is moved
      swapEmployees(employeeIdToMove, desk.employeeId, oldDesk, desk);
    } else if (oldDesk && desk.dummyEmployee) {
      //desk is occupied by dummy and employee is moved
      swapEmployeeDummyEmployee(oldDesk, desk);
    } else if ((desk.employeeId || desk.dummyEmployee) && !oldDesk) {
      //employee is moved from list and dropped on desk with employee or dummy
      setReplaceEmployeeConfirmationInfo({
        employeeId: employeeIdToMove,
        to: desk,
        from: oldDesk,
        replaceDummy: desk.dummyEmployee ? true : false,
      });
    }
    setDraggingEmployee(false);
    setDraggingListEmployee(false);
  };

  const swapEmployees = (
    movingEmployeeId,
    currentEmployeeId,
    oldDesk,
    newDesk
  ) => {
    setSelectedDeskId(null);
    let newDeskList = currentSave.desks.slice();
    // put movingEmployee on new desk
    const newDeskWithNewEmployee = updateEmployeeOnDesk(
      newDesk,
      movingEmployeeId
    );
    let newDeskIndex = newDeskList.findIndex(
      (x) => x.id === newDeskWithNewEmployee.id
    );
    newDeskList[newDeskIndex] = newDeskWithNewEmployee;

    //put old employee on old desk
    const oldDeskWithNewEmployee = updateEmployeeOnDesk(
      oldDesk,
      currentEmployeeId
    );
    let newOldDeskIndex = newDeskList.findIndex(
      (x) => x.id === oldDeskWithNewEmployee.id
    );
    newDeskList[newOldDeskIndex] = oldDeskWithNewEmployee;

    updateDesks(newDeskList);
  };

  const onDropDummyEmployee = (event, desk) => {
    if (!desk) return;
    setSelectedDeskId(null);
    setDraggingDummyEmployee(false);
    let oldDesk = JSON.parse(event.dataTransfer.getData("currentDesk"));

    if (oldDesk.id === desk.id) return;

    if (!desk.employeeId && !desk.dummyEmployee) {
      let newDeskList = currentSave.desks.slice();

      let changedDesk = { ...desk };
      changedDesk.dummyEmployee = oldDesk.dummyEmployee;
      let newDeskIndex = newDeskList.findIndex((x) => x.id === changedDesk.id);
      newDeskList[newDeskIndex] = changedDesk;

      let changedOldDesk = { ...oldDesk };
      delete changedOldDesk.dummyEmployee;
      let oldDeskIndex = newDeskList.findIndex(
        (x) => x.id === changedOldDesk.id
      );
      newDeskList[oldDeskIndex] = changedOldDesk;

      updateDesks(newDeskList);
    } else if (desk.employeeId) {
      swapEmployeeDummyEmployee(desk, oldDesk);
    } else if (desk.dummyEmployee) {
      swapDummyEmployees(desk, oldDesk);
    }
  };

  const swapEmployeeDummyEmployee = (deskWithEmployee, deskWithDummy) => {
    let newDeskList = currentSave.desks.slice();

    let movingDummyEmployee = deskWithDummy.dummyEmployee;
    let movingEmployeeId = deskWithEmployee.employeeId;

    //replace employee with dummy
    let newDeskWithDummy = updateEmployeeOnDesk(deskWithEmployee, null);
    newDeskWithDummy.dummyEmployee = movingDummyEmployee;

    let newDeskWithDummyIndex = newDeskList.findIndex(
      (x) => x.id === newDeskWithDummy.id
    );
    newDeskList[newDeskWithDummyIndex] = newDeskWithDummy;

    //replace dummy with employee
    let newDeskWithEmployee = updateEmployeeOnDesk(
      deskWithDummy,
      movingEmployeeId
    );
    delete newDeskWithEmployee.dummyEmployee;

    let newDeskWithEmployeeIndex = newDeskList.findIndex(
      (x) => x.id === newDeskWithEmployee.id
    );
    newDeskList[newDeskWithEmployeeIndex] = newDeskWithEmployee;

    updateDesks(newDeskList);
  };

  const swapDummyEmployees = (desk1, desk2) => {
    let newDeskList = currentSave.desks.slice();

    const movingDummy1 = desk1.dummyEmployee;
    const movingDummy2 = desk2.dummyEmployee;

    let changedDesk1 = { ...desk1 };
    changedDesk1.dummyEmployee = movingDummy2;

    let changedDesk1Index = newDeskList.findIndex(
      (x) => x.id === changedDesk1.id
    );
    newDeskList[changedDesk1Index] = changedDesk1;

    let changedDesk2 = { ...desk2 };
    changedDesk2.dummyEmployee = movingDummy1;

    let changedDesk2Index = newDeskList.findIndex(
      (x) => x.id === changedDesk2.id
    );
    newDeskList[changedDesk2Index] = changedDesk2;

    updateDesks(newDeskList);
  };

  const moveEmployee = (newDesk, employeeIdToMove, oldDesk) => {
    setSelectedDeskId(null);
    let newDeskList = currentSave.desks.slice();
    // if an employee came from a desk, delete from old desk
    if (oldDesk) {
      const newEmptyDesk = updateEmployeeOnDesk(oldDesk, null);
      let newEmptyDeskIndex = newDeskList.findIndex(
        (x) => x.id === newEmptyDesk.id
      );
      newDeskList[newEmptyDeskIndex] = newEmptyDesk;
    }
    // if an employee has a new desk, move employee and delete dummy employee if there is one
    if (newDesk) {
      let newDeskWithEmployee = updateEmployeeOnDesk(newDesk, employeeIdToMove);
      if (newDeskWithEmployee.dummyEmployee) {
        delete newDeskWithEmployee.dummyEmployee;
      }
      let newDeskIndex = newDeskList.findIndex(
        (x) => x.id === newDeskWithEmployee.id
      );
      newDeskList[newDeskIndex] = newDeskWithEmployee;
    }
    updateDesks(newDeskList);
  };

  const updateEmployeeOnDesk = (desk, employeeId) => {
    let deskToChange = { ...desk };
    deskToChange.employeeId = employeeId;
    return deskToChange;
  };

  const removeAllEmployeesFromFloor = () => {
    let placedEmployeesToRemove = [];
    let newDeskList = currentSave.desks.slice();
    for (let i = 0; i < newDeskList.length; i++) {
      let desk = newDeskList[i];
      if (
        (desk.employeeId || desk.dummyEmployee) &&
        desk.floorPartId === selectedFloorPart.id
      ) {
        if (desk.employeeId) {
          placedEmployeesToRemove.push(desk.employeeId);
        }

        newDeskList[i].employeeId = null;
        delete newDeskList[i].dummyEmployee;
      }
    }

    updateDesks(newDeskList);
  };

  const removeAllEmployees = () => {
    let newDeskList = currentSave.desks.slice();
    for (let i = 0; i < newDeskList.length; i++) {
      let desk = newDeskList[i];
      if (desk.employeeId || desk.dummyEmployee) {
        newDeskList[i].employeeId = null;
        delete newDeskList[i].dummyEmployee;
      }
      updateDesks(newDeskList);
    }
  };

  // DESKS

  const [selectedDeskId, setSelectedDeskIdInternal] = useState();
  const setSelectedDeskId = useCallback(
    (id) => {
      setSelectedDeskIdInternal(id);
      setInitSelected(false);
    },
    [setSelectedDeskIdInternal, setInitSelected]
  );

  const [draggingToAddDesk, setDraggingToAddDesk] = useState(false);
  const [draggingDesk, setDraggingDesk] = useState(false);

  const [draggingDummyEmployee, setDraggingDummyEmployee] = useState(false);
  const [showDeleteDeskConfirmation, setShowDeleteDeskConfirmation] = useState(
    false
  );

  useEffect(() => {
    window.addEventListener("click", unselectDesk);
    return () => {
      window.removeEventListener("click", unselectDesk);
    };
  });

  useKeyPress("r", () => {
    if (!editing) return;
    if (selectedDeskId && isAdmin) {
      const deskToRotate = currentSave.desks.find(
        (desk) => desk.id === selectedDeskId
      );
      rotateDesk(deskToRotate);
    }
  });

  useKeyPress("delete", () => {
    if (!editing) return;
    if (selectedDeskId && isAdmin) {
      const deskToDelete = currentSave.desks.find(
        (desk) => desk.id === selectedDeskId
      );
      if (deskToDelete.employeeId || deskToDelete.dummyEmployee) {
        setShowDeleteDeskConfirmation(true);
      } else {
        setSelectedDeskId(null);
        deleteDesk(
          deskToDelete,
          deskToDelete.employeeId ? deskToDelete.employeeId : null
        );
      }
    }
  });

  //if selectedDesk is not on the selected floorpart, reset selectedDesk
  useEffect(() => {
    if (currentSave.desks) {
      const desk = currentSave.desks.find((desk) => desk.id === selectedDeskId);
      if (
        selectedFloorPart &&
        desk &&
        selectedFloorPart.id !== desk.floorPartId
      ) {
        setSelectedDeskId(null);
      }
    }
  }, [selectedFloorPart, selectedDeskId, currentSave]);

  // Clicking outside desk or employee card unselects the desk
  const unselectDesk = (event) => {
    const className = event?.target?.className;
    if (typeof className.includes === "undefined") return;
    if (
      className.includes("grid-layout") ||
      className.includes("seating-header") ||
      className.includes("floor-title") ||
      className.includes("grid-layout-container") ||
      className.includes("seating-container")
    ) {
      setSelectedDeskId(null);
    }
  };

  //function for updating a desk
  const updateDesk = (alteredDesk) => {
    if (!alteredDesk) return;
    const newDeskList = currentSave.desks.slice();

    let foundIndex = newDeskList.findIndex(
      (desk) => desk.id === alteredDesk.id
    );
    newDeskList[foundIndex] = alteredDesk;

    updateDesks(newDeskList);
  };

  const [floorsTotalDesks, setFloorsTotalDesks] = useState();
  const [floorsTotalEmployees, setFloorsTotalEmployees] = useState();

  // Get total desks
  useEffect(() => {
    if (!currentSave || !selectedFloorPart) return;
    const desks = currentSave.desks.filter(
      (d) => d.floorPartId === selectedFloorPart.id
    );
    const desksWithEmployees = desks.filter(
      (d) => d.employeeId || d.dummyEmployee
    );
    setFloorsTotalEmployees(desksWithEmployees?.length);
    setFloorsTotalDesks(desks?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSave, selectedFloorPart]);

  const getDesks = () => {
    if (!currentSave || !employees) return;
    const newDeskList = currentSave.desks.map((desk) => {
      const width =
        desk.rotated !== 0 && desk.rotated !== 180
          ? gridProps.deskHeight
          : gridProps.deskWidth;
      const height =
        desk.rotated !== 0 && desk.rotated !== 180
          ? gridProps.deskWidth
          : gridProps.deskHeight;

      let dataGrid = { x: desk.x, y: desk.y, w: width, h: height };
      if (draggingToAddDesk) {
        dataGrid = { ...dataGrid, static: true };
      }
      const employee = getEmployee(desk.employeeId);
      const employeeComment = employee
        ? currentSave.comments.find(
            (comment) => comment.employeeId === employee.id
          )
        : null;
      const department = employee
        ? departments.find((dep) => dep.name === employee.department)
        : null;

      if (desk.floorPartId === selectedFloorPart.id) {
        return (
          <Desk
            data-grid={dataGrid}
            key={desk.id}
            selected={desk.id === selectedDeskId}
            rotateDesk={rotateDesk}
            {...{
              desk,
              updateDesk,
              employee,
              employeeComment,
              department,
              onDragEmployeeOver,
              onDropEmployee,
              onDropDummyEmployee,
              draggingEmployee,
              setDraggingEmployee,
              lockEmployees,
              selectedDeskId,
              setSelectedDeskId,
              draggingDummyEmployee,
              setDraggingDummyEmployee,
              draggingDesk: desk.id === selectedDeskId && draggingDesk,
              draggingToAddDesk,
              deleteDesk,
              showDeleteDeskConfirmation,
              setShowDeleteDeskConfirmation,
              initSelected,
            }}
          />
        );
      }
      return null;
    });
    return newDeskList.filter((desk) => desk !== null);
  };

  const addDeskFromDrag = (deskToAdd) => {
    let newDeskList = currentSave.desks.slice();
    const newDesk = {
      id: uuidv4(),
      x: deskToAdd.x,
      y: deskToAdd.y,
      rotated: 0,
      floorPartId: selectedFloorPart.id,
    };

    newDeskList.push(newDesk);
    updateDesks(newDeskList);
  };

  const deleteDesk = (desk, employeeId) => {
    const newDeskList = currentSave.desks.slice();

    const indexOfDeskToDelete = newDeskList.findIndex(
      (newItem) => newItem.id === desk.id
    );
    newDeskList.splice(indexOfDeskToDelete, 1);
    updateDesks(newDeskList);
  };

  const deleteAllDesksFromFloor = () => {
    let desksToRemove = [];
    let placedEmployeesToRemove = [];

    for (let i = 0; i < currentSave.desks.length; i++) {
      if (currentSave.desks[i].floorPartId === selectedFloorPart.id) {
        if (currentSave.desks[i].employeeId) {
          placedEmployeesToRemove.push(currentSave.desks[i].employeeId);
        }
        desksToRemove.push(currentSave.desks[i].id);
      }
    }

    const newDeskList = currentSave.desks.filter(
      (desk) => !desksToRemove.includes(desk.id)
    );

    updateDesks(newDeskList);
  };

  const deleteAllDesks = () => {
    updateDesks([]);
  };

  const rotateDesk = (deskToRotate) => {
    if (!deskToRotate) return;
    let alteredDesk = { ...deskToRotate };
    alteredDesk.w = deskToRotate.h;
    alteredDesk.h = deskToRotate.w;
    if (deskToRotate.rotated !== 270) {
      alteredDesk.rotated = deskToRotate.rotated + 90;
    } else {
      alteredDesk.rotated = 0;
    }

    updateDesk(alteredDesk);
  };

  const updateDeskPosition = (layoutItem) => {
    let deskToChange = currentSave.desks.find(
      (item) => item.id === layoutItem.i
    );

    const desk = {
      ...deskToChange,
      x: layoutItem.x,
      y: layoutItem.y,
    };

    updateDesk(desk);
  };

  const getReplaceEmployeeConfirmationModal = () => {
    const movingeEmployee = getEmployee(
      replaceEmployeeConfirmationInfo.employeeId
    );
    const currentEmployee = getEmployee(
      replaceEmployeeConfirmationInfo.to.employeeId
    );
    let modalText;

    if (replaceEmployeeConfirmationInfo.replaceDummy) {
      modalText = (
        <p className="modal-text">
          Would you like to replace the dummy employee with{" "}
          <b>
            {movingeEmployee.firstName} {movingeEmployee.lastName}
          </b>
          ? The dummy employee will be deleted.
        </p>
      );
    } else {
      modalText = (
        <p className="modal-text">
          Would you like to replace{" "}
          <b>
            {currentEmployee.firstName} {currentEmployee.lastName}
          </b>{" "}
          with{" "}
          <b>
            {movingeEmployee.firstName} {movingeEmployee.lastName}
          </b>
          ?{" "}
          <b>
            {currentEmployee.firstName} {currentEmployee.lastName}
          </b>{" "}
          will be moved back to the list.
        </p>
      );
    }
    return (
      <Modal
        header={`Replace ${
          replaceEmployeeConfirmationInfo.replaceDummy ? "dummy " : ""
        }employee?`}
        isOpen={!!replaceEmployeeConfirmationInfo}
        onRequestClose={() => setReplaceEmployeeConfirmationInfo(null)}
      >
        {modalText}
        <Modal.Footer>
          <div className="modal-btns-container">
            <Button
              onClick={() => {
                setReplaceEmployeeConfirmationInfo(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              onClick={() => {
                moveEmployee(
                  replaceEmployeeConfirmationInfo.to,
                  replaceEmployeeConfirmationInfo.employeeId,
                  replaceEmployeeConfirmationInfo.from
                );
                setReplaceEmployeeConfirmationInfo(null);
              }}
            >
              Replace
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  if (
    !employees ||
    !currentSave ||
    !locations ||
    !selectedFloorPart ||
    !selectedFloor ||
    !selectedLocation
  ) {
    return (
      <div className="before-load-spinner">
        <Spinner label="Loading content..." />
      </div>
    );
  }
  return (
    <>
      <div className={!editing ? "map view-mode" : "map edit-mode"}>
        <Nav
          appName="Seating"
          hideTheme
          hideCollapse
          top={
            <ToolMenu
              selectedDesk={currentSave.desks.find(
                (desk) => desk.id === selectedDeskId
              )}
              {...{
                setSelectedDeskId,
                lockEmployees,
                setLockEmployees,
                setDraggingToAddDesk,
              }}
            />
          }
          side={
            !editing && (
              <SideMenu
                placedEmployeesList={placedEmployeesList}
                moveEmployee={moveEmployee}
                draggingDummyEmployee={draggingDummyEmployee}
                lockEmployees={lockEmployees}
                draggingEmployee={draggingEmployee}
                setDraggingEmployee={setDraggingEmployee}
                draggingListEmployee={draggingListEmployee}
                setDraggingListEmployee={setDraggingListEmployee}
                findDesk={findDesk}
                setSelectedDeskId={setSelectedDeskId}
                draggingToAddDesk={draggingToAddDesk}
                employeeDepartmentFromDeletedDesk={
                  employeeDepartmentFromDeletedDesk
                }
                setEmployeeDepartmentFromDeletedDesk={
                  setEmployeeDepartmentFromDeletedDesk
                }
              />
            )
          }
        >
          <>
            {showSpinner && <Spinner overlay />}
            {editing && (
              <SideMenu
                placedEmployeesList={placedEmployeesList}
                moveEmployee={moveEmployee}
                draggingDummyEmployee={draggingDummyEmployee}
                lockEmployees={lockEmployees}
                draggingEmployee={draggingEmployee}
                setDraggingEmployee={setDraggingEmployee}
                draggingListEmployee={draggingListEmployee}
                setDraggingListEmployee={setDraggingListEmployee}
                findDesk={findDesk}
                setSelectedDeskId={setSelectedDeskId}
                draggingToAddDesk={draggingToAddDesk}
                employeeDepartmentFromDeletedDesk={
                  employeeDepartmentFromDeletedDesk
                }
                setEmployeeDepartmentFromDeletedDesk={
                  setEmployeeDepartmentFromDeletedDesk
                }
              />
            )}

            <div className="seating-container">
              <SeatingHeader
                removeAllEmployeesFromFloor={removeAllEmployeesFromFloor}
                removeAllEmployees={removeAllEmployees}
                deleteAllDesksFromFloor={deleteAllDesksFromFloor}
                deleteAllDesks={deleteAllDesks}
                floorsTotalDesks={floorsTotalDesks}
                floorsTotalEmployees={floorsTotalEmployees}
              />
              <div className="grid-layout-container bf-scrollbar">
                <GridLayout
                  className="grid-layout"
                  style={floorImage}
                  cols={gridProps.nmbrOfRowsCols}
                  layout={getDesks()}
                  maxRows={
                    gridProps.gridHeight /
                    (gridProps.gridWidth / gridProps.nmbrOfRowsCols)
                  }
                  rowHeight={gridProps.gridWidth / gridProps.nmbrOfRowsCols}
                  width={gridProps.gridWidth}
                  autoSize={false}
                  compactType={null}
                  preventCollision={true}
                  margin={[0, 0]}
                  isResizable={false}
                  isDraggable={editing && isAdmin}
                  draggableHandle={".desk"}
                  draggableCancel={`${
                    !lockEmployees ? ".desk-employee, .dummy-employee" : ""
                  }`}
                  onDragStop={(
                    layout,
                    oldItem,
                    newItem,
                    placeholder,
                    event
                  ) => {
                    event.stopPropagation();
                    setDraggingDesk(false);
                    updateDeskPosition(newItem);
                  }}
                  onDragStart={(layout, oldItem, newItem) => {
                    if (draggingToAddDesk) {
                      newItem.h = gridProps.deskHeight;
                      newItem.w = gridProps.deskWidth;
                    }
                  }}
                  onDrag={() => {
                    setDraggingDesk(true);
                  }}
                  isDroppable={draggingToAddDesk && isAdmin}
                  onDrop={(_, element, event) => {
                    event.preventDefault();
                    addDeskFromDrag(element);
                  }}
                >
                  {getDesks()}
                </GridLayout>
              </div>
            </div>
            {replaceEmployeeConfirmationInfo &&
              getReplaceEmployeeConfirmationModal()}
          </>

          {showMessage && editing && (
            <Message
              className="custom-message"
              header="Friendly reminder!"
              onClose={() => setShowMessage(false)}
            >
              Remember to save your project to not lose changes.
            </Message>
          )}

          {removedEmployees && (
            <>
              <Message
                className="custom-message"
                header="Employee automatically removed"
                onClose={() => setRemovedEmployees(false)}
              >
                One or more placed employees are missing in the database, and
                have been automatically removed. Usually this happens when an
                employee has quit.
              </Message>
            </>
          )}
        </Nav>
      </div>
    </>
  );
};

export default ReactGridLayoutPage;
