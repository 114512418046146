import React, { useEffect, useState } from "react";

import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  Icon,
  Spinner,
  AutoCol,
  Input,
  Select,
} from "@intility/bifrost-react";

import { useSave } from "contexts/saveContext";
import { useRole } from "contexts/roleContext";
import CreateDraftModal from "./createDraftModal";
import Draft from "./draft";
import "../../styles/css/dashboard.css";

const Drafts = () => {
  const { saves } = useSave();
  const { isAdmin } = useRole();

  const [modalOpen, setModalOpen] = useState(false);
  const [filteredDrafts, setFilteredDrafts] = useState();
  const [searchValue, setSearchValue] = useState();
  const [selectedSort, setSelectedSort] = useState();

  const sortOptions = [
    { value: "modified", label: "Last modified" },
    { value: "createdAsc", label: "First created" },
    { value: "createdDesc", label: "Last created" },
    { value: "nameAsc", label: "Draft name (A-Z)" },
    { value: "nameDesc", label: "Draft name (Z-A)" },
  ];

  // When something changes
  useEffect(() => {
    if (!saves) return;
    sortAndFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saves, searchValue, selectedSort]);

  // Sorting and filtering drafts
  const sortAndFilter = () => {
    const s = sortSaves();
    const sorted = s.slice();

    if (!searchValue) {
      setFilteredDrafts(sorted);
    } else {
      const filtered = sorted.filter((save) =>
        save.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredDrafts(filtered);
    }
  };

  // Sorting saves
  const sortSaves = () => {
    switch (selectedSort?.value) {
      case "nameAsc":
        return saves.sort((a, b) => a.name.localeCompare(b.name));
      case "nameDesc":
        return saves.sort((a, b) => b.name.localeCompare(a.name));
      case "createdAsc":
        return saves.sort((a, b) => new Date(a.created) - new Date(b.created));
      case "createdDesc":
        return saves.sort((a, b) => new Date(b.created) - new Date(a.created));
      case "modified":
        return saves.sort(
          (a, b) => new Date(b.modified) - new Date(a.modified)
        );
      default:
        return saves.sort(
          (a, b) => new Date(b.modified) - new Date(a.modified)
        );
    }
  };

  return (
    <div className="drafts-container">
      <h5 className="drafts-title bf-h5"> Drafts </h5>
      <p className="drafts-description bf-p">
        Drafts are only visible for users with edit-roles
      </p>
      <div className="search-sort-container">
        <Input
          label="Search drafts"
          hideLabel
          placeholder="Search for draft"
          icon={faSearch}
          onChange={(e) => setSearchValue(e.target.value)}
          clearable
        />
        <Select
          label="Sort drafts"
          hideLabel
          options={sortOptions}
          value={selectedSort}
          onChange={(item) => setSelectedSort(item)}
          placeholder="Last modified"
        />
      </div>

      {!filteredDrafts && <Spinner size={45} />}
      <div className="autocol-container bf-scrollbar-small">
        <AutoCol width={250} gap={12}>
          {filteredDrafts &&
            filteredDrafts.map((save) => <Draft key={save.id} save={save} />)}
        </AutoCol>
      </div>

      {isAdmin && (
        <Button
          className="create-new-draft"
          onClick={() => setModalOpen(true)}
          variant="filled"
        >
          <Icon icon={faPlus} /> Create new draft
        </Button>
      )}

      {modalOpen && (
        <CreateDraftModal
          open={modalOpen}
          setOpen={setModalOpen}
          saves={saves}
        />
      )}
    </div>
  );
};

export default Drafts;
