import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { Spinner } from "@intility/bifrost-react";
import { useAuth, useUser } from "@intility/react-msal-browser";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";

import { useLoading } from "./loadingContext";
import { useRole } from "./roleContext";
import ErrorMessage from "components/errorMessage";

const SaveContext = createContext(null);
const useSave = () => useContext(SaveContext);

const SaveProvider = (props) => {
  const { authorizedFetch } = useAuth();
  const user = useUser();
  let history = useHistory();

  const { setShowSpinner } = useLoading();

  const [hasChanged, setHasChanged] = useState(false);

  const [currentSave, setCurrentSave] = useState();

  const { draftId } = useParams();

  const [selectedSaveId, setSelectedSaveId] = useState(draftId);

  const [removedEmployees, setRemovedEmployees] = useState(false);

  const { data: editingData, revalidate: revalidateEditingEmployeeId } = useSWR(
    () =>
      currentSave &&
      `${process.env.REACT_APP_API_URL}/api/saves/${currentSave.id}/editingEmployeeId`,
    {
      refreshInterval: currentSave?.currentlyEditingEmployeeId ? 30000 : null,
    }
  );
  const editingEmployeeId = editingData?.currentlyEditingEmployeeId;

  const editing = editingEmployeeId === user?.homeAccountId.split(".")[0];

  const { isAdmin, isEditor } = useRole();

  // Fetching all saves
  const { data: saves, revalidate: revalidateAllSaves, error } = useSWR(
    isAdmin || isEditor
      ? `${process.env.REACT_APP_API_URL}/api/saves/basic`
      : null,
    {
      revalidateOnFocus: editing ? false : true,
    }
  );

  // Fetching selected saves
  const { data: selectedSave, revalidate: revalidateSelectedSave } = useSWR(
    () =>
      selectedSaveId
        ? `${process.env.REACT_APP_API_URL}/api/saves/${selectedSaveId}`
        : `${process.env.REACT_APP_API_URL}/api/saves/public`,
    {
      revalidateOnFocus: editing ? false : true,
    }
  );

  // Setting selected save as current save
  useEffect(() => {
    if (!selectedSave) return;
    setCurrentSave(selectedSave);
  }, [selectedSave]);

  useEffect(() => {
    if (!editing) {
      revalidateSelectedSave();
    }
  }, [editing, revalidateSelectedSave]);

  const getSave = async (save) => {
    if (!save.id) return;
    try {
      const res = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves/${save.id}`
      );
      if (!res.ok) {
        throw new Error("Could not fetch save");
      }
      const data = await res.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  // Creating array with IDs of placed employees
  const placedEmployeesList = useMemo(() => {
    const placedEmployeesListArray = [];
    if (currentSave) {
      currentSave.desks?.forEach((d) => {
        if (d.employeeId) {
          placedEmployeesListArray.push(d.employeeId);
        }
      });
    }
    return placedEmployeesListArray;
  }, [currentSave]);

  // Discarding changes
  const discardSaveChanges = async (save = currentSave) => {
    const oldSave = await getSave(save);
    const editingSave = {
      ...oldSave,
      beingEdited: false,
      currentlyEditingEmployeeId: null,
    };
    setHasChanged(false);
    updateBasicSave(editingSave);
    setCurrentSave(editingSave);
  };

  // Creating a new save
  const postNewSave = async () => {
    setShowSpinner(true);
    try {
      currentSave.beingEdited = false;
      currentSave.currentlyEditingEmployeeId = null;

      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(currentSave),
        }
      );
      if (!response.ok) {
        throw new Error("Could not save draft");
      }
      const data = await response.json();
      history.push(`/${data.id}`);
      setCurrentSave(data);
      setHasChanged(false);
    } finally {
      revalidateAllSaves();
      setShowSpinner(false);
    }
  };

  // Updating basic save
  const updateBasicSave = async (save) => {
    if (!save.id) return;
    setShowSpinner(true);
    try {
      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves/basic/${save.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(save),
        }
      );
      if (!response.ok) {
        throw new Error("Could not save changes");
      }
    } finally {
      revalidateEditingEmployeeId();
      revalidateAllSaves();
      revalidateSelectedSave();
      setShowSpinner(false);
    }
  };

  const updateSelectedSave = async () => {
    setShowSpinner(true);

    if (currentSave.publicSave) {
      const savesWithoutCurrent = saves.filter((x) => x.id !== currentSave.id);
      const newPublicsave = savesWithoutCurrent.find((x) => x.publicSave);
      if (newPublicsave) {
        currentSave.publicSave = false;
      }
    }

    try {
      currentSave.beingEdited = false;
      currentSave.currentlyEditingEmployeeId = null;

      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves/${currentSave.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(currentSave),
        }
      );
      if (!response.ok) {
        throw new Error("Could not save changes");
      }

      setHasChanged(false);
    } finally {
      revalidateEditingEmployeeId();
      revalidateAllSaves();
      revalidateSelectedSave();
      setShowSpinner(false);
    }
  };

  // Clicking save button
  const saveChanges = async () => {
    if (!currentSave.id) {
      postNewSave();
    } else {
      updateSelectedSave();
    }
  };

  // Updating desks
  const updateDesks = (deskList) => {
    setHasChanged(true);
    let newCurrentSave = {
      ...currentSave,
      desks: deskList,
    };
    setCurrentSave(newCurrentSave);
  };

  // Updating comments
  const updateComments = (commentList) => {
    setHasChanged(true);
    let newCurrentSave = {
      ...currentSave,
      comments: commentList,
    };
    setCurrentSave(newCurrentSave);
  };

  // Creating new save
  const createNewDraft = async (save) => {
    setCurrentSave(save);
    history.push("/");
  };

  // Opens selected save
  const openDraft = async (save) => {
    setShowSpinner(true);
    if (save.id === currentSave.id) {
      history.push(`/${save.id}`);
      setShowSpinner(false);
      return;
    }
    try {
      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves/${save.id}`
      );
      if (!response.ok) {
        throw new Error("Could not load selected save");
      }
      const data = await response.json();
      setCurrentSave(data);
      history.push(`/${save.id}`);
    } finally {
      setShowSpinner(false);
    }
  };

  // Duplicating a draft
  const duplicateDraft = async (save) => {
    setShowSpinner(true);

    // Get desks and comments
    const oldSave = await getSave(save);
    if (!oldSave) return;

    const id = user?.homeAccountId.split(".")[0];

    const desks = oldSave.desks.slice();
    desks.forEach((desk) => {
      delete desk.id;
      delete desk.saveId;
    });
    const comments = oldSave.comments.slice();
    comments.forEach((comment) => {
      delete comment.id;
      delete comment.saveId;
    });

    const newSave = {
      name: oldSave?.name + " copy",
      createdByEmployeeId: id,
      lastEditedByEmployeeId: id,
      publicSave: false,
      beingEdited: false,
      desks: desks,
      comments: comments,
    };

    try {
      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newSave),
        }
      );
      if (!response.ok) {
        throw new Error("Could not duplicate draft");
      }
      setHasChanged(false);
    } finally {
      setShowSpinner(false);
      revalidateAllSaves();
    }
  };

  // Deletes save
  const deleteDraft = async (id) => {
    if (!id) return;
    setShowSpinner(true);
    try {
      const response = await authorizedFetch(
        `${process.env.REACT_APP_API_URL}/api/saves/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Could not delete draft");
      }

      history.push("/dashboard");
    } finally {
      revalidateAllSaves();
      setShowSpinner(false);
    }
  };

  // Check for employees that have been deleted and clean up their desks
  const cleanUpRemovedEmployees = async (employeeIds) => {
    let updatedDesks = currentSave.desks.slice();
    let foundEmployeesToRemove = false;

    currentSave.desks.forEach((desk) => {
      if (desk.employeeId && !employeeIds.includes(desk.employeeId)) {
        foundEmployeesToRemove = true;
        setRemovedEmployees(true);

        let newEmptyDesk = { ...desk, employeeId: null };
        let deskIndex = updatedDesks.findIndex((x) => x.id === newEmptyDesk.id);
        updatedDesks[deskIndex] = newEmptyDesk;
      }
    });
    if (foundEmployeesToRemove) {
      if (currentSave.publicSave) {
        const savesWithoutCurrent = saves.filter(
          (x) => x.id !== currentSave.id
        );
        const newPublicsave = savesWithoutCurrent.find((x) => x.publicSave);
        if (newPublicsave) {
          currentSave.publicSave = false;
        }
      }

      try {
        currentSave.desks = updatedDesks;
        const response = await authorizedFetch(
          `${process.env.REACT_APP_API_URL}/api/saves/${currentSave.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(currentSave),
          }
        );
        if (!response.ok) {
          throw new Error("Could not save changes");
        }
        setHasChanged(false);
      } finally {
        revalidateAllSaves();
        revalidateSelectedSave();
        revalidateEditingEmployeeId();
        setShowSpinner(false);
      }
    }
    return foundEmployeesToRemove;
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!currentSave) {
    return (
      <div className="before-load-spinner">
        <Spinner label="Loading save..." />
      </div>
    );
  }

  return (
    <SaveContext.Provider
      value={{
        placedEmployeesList,
        saves,
        currentSave,
        editingEmployeeId,
        editing: currentSave && currentSave.id ? editing : true,
        revalidateEditingEmployeeId,
        revalidateAllSaves,
        revalidateSelectedSave,
        selectedSaveId,
        setSelectedSaveId,
        createNewDraft,
        openDraft,
        duplicateDraft,
        deleteDraft,
        updateBasicSave,
        updateDesks,
        updateComments,
        discardSaveChanges,
        saveChanges,
        hasChanged,
        cleanUpRemovedEmployees,
        removedEmployees,
        setRemovedEmployees,
      }}
    >
      {props && props.children}
    </SaveContext.Provider>
  );
};

export { useSave };
export default SaveProvider;
