import React, { useEffect } from "react";

import { Nav, Spinner } from "@intility/bifrost-react";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";

import { useLoading } from "contexts/loadingContext";
import DashboardHeader from "components/dashboard/dashboardHeader";
import Drafts from "components/dashboard/drafts";
import MapSelect from "components/dashboard/mapSelect";
import "../styles/css/dashboard.css";
import { useRole } from "contexts/roleContext";

const Dashboard = () => {
  const { isAdmin, isEditor } = useRole();
  const { showSpinner } = useLoading();
  let history = useHistory();

  useEffect(() => {
    if (!isAdmin && !isEditor) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const update = setInterval(() => {
      mutate(`${process.env.REACT_APP_API_URL}/api/saves/basic`, false);
    }, 10000);
    return () => clearInterval(update);
  }, []);

  return (
    <Nav appName="Seating" hideTheme top={<DashboardHeader />}>
      {showSpinner && <Spinner overlay />}
      <div className="dashboard-container">
        <h1 className="bf-h1"> Dashboard </h1>
        {isAdmin && <MapSelect />}
        <Drafts />
      </div>
    </Nav>
  );
};

export default Dashboard;
