import React, { useState, useEffect, useMemo, useCallback } from "react";

import Department from "../components/department";
import ListEmployee from "../components/listEmployee";

const DepartmentEditMode = ({
  department,
  placedEmployeesList,
  showPlaced,
  showSearchResults,
  setDraggingListEmployee,
  setDraggingEmployee,
  employeeDropped,
  setDroppedEmployeeDepartment,
  setSelectedDeskId,
  findDesk,
  openAll,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = useCallback(
    (newOpen) => {
      if (!newOpen) {
        setDroppedEmployeeDepartment("");
      }
      setOpen(newOpen);
    },
    [setDroppedEmployeeDepartment]
  );

  const numberOfUnplacedEmployees = useMemo(
    () =>
      department.employees.filter(
        (employee) => !placedEmployeesList.includes(employee.id)
      ).length,
    [department.employees, placedEmployeesList]
  );

  useEffect(() => {
    if (showSearchResults) {
      setOpen(true);
    }
  }, [showSearchResults]);

  // Open or close all
  useEffect(() => {
    if (openAll) {
      setOpen(true);
    } else if (!openAll && !employeeDropped) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAll]);

  // open if an employee has been dropped in the department
  useEffect(() => {
    if (employeeDropped) {
      setOpen(true);
    }
  }, [employeeDropped]);

  //close if no employees
  useEffect(() => {
    if (!showPlaced && numberOfUnplacedEmployees === 0 && !showSearchResults) {
      setOpen(false);
    }
  }, [numberOfUnplacedEmployees, showPlaced, showSearchResults]);

  const getEmployees = (employees) => {
    return employees.map((employee) => {
      const placed = placedEmployeesList.includes(employee.id);
      if (!placed || showPlaced) {
        return (
          <ListEmployee
            key={employee.id}
            employee={employee}
            placed={placed}
            findDesk={findDesk}
            currentDesk={null}
            showPlaced={showPlaced}
            setDraggingListEmployee={setDraggingListEmployee}
            setDraggingEmployee={setDraggingEmployee}
            setSelectedDeskId={setSelectedDeskId}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Department
        department={department}
        employees={getEmployees(department.employees)}
        showPlaced={showPlaced}
        numberOfEmployees={numberOfUnplacedEmployees}
        open={open}
        setOpen={handleOpenChange}
      />
    </>
  );
};

export default DepartmentEditMode;
