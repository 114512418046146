import React from "react";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Button, Modal } from "@intility/bifrost-react";

import { useSave } from "contexts/saveContext";

const DeleteDraftModal = ({ open, setOpen, save }) => {
  const { deleteDraft } = useSave();

  const handleDeleteDraft = () => {
    deleteDraft(save.id);
  };

  return (
    <Modal
      className="add-new-draft-modal"
      header={`Delete '${save.name}'`}
      icon={faTimes}
      isOpen={open}
      onRequestClose={() => setOpen(false)}
    >
      <p className="bf-p">
        Are you sure you want to delete this draft?{" "}
        <b>You can't undo this action.</b>
      </p>

      <Modal.Footer>
        <div className="buttons-container">
          <Button onClick={() => setOpen(false)}>Cancel</Button>

          <Button variant="filled" onClick={() => handleDeleteDraft()}>
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDraftModal;
