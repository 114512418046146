import React from "react";

import { useSave } from "contexts/saveContext";
import "../../styles/css/dashboard.css";
import { useRole } from "contexts/roleContext";

const DraftDropdown = ({
  save,
  setShowDropdown,
  setRenameDraftModalOpen,
  setDeleteDraftModalOpen,
  setUnlockDraftModalOpen,
}) => {
  const { openDraft, duplicateDraft } = useSave();
  const { isAdmin } = useRole();

  const handleOnBlur = () => {
    setShowDropdown(false);
  };

  return (
    <div className="drop-down-content">
      <div>
        <button
          className="bf-title-link"
          onClick={() => {
            setShowDropdown(false);
            openDraft(save);
          }}
        >
          Open
        </button>
        <button
          className="bf-title-link"
          onClick={() => {
            setShowDropdown(false);
            setRenameDraftModalOpen(true);
          }}
        >
          Rename
        </button>
        <button
          className="bf-title-link"
          onClick={() => {
            setShowDropdown(false);
            duplicateDraft(save);
          }}
          onBlur={() => save.publicSave && handleOnBlur()}
        >
          Duplicate
        </button>
        {save.beingEdited && isAdmin && (
          <button
            className="bf-title-link"
            onClick={() => {
              setUnlockDraftModalOpen(true);
            }}
            onBlur={() => save.beingEdited && handleOnBlur()}
          >
            Unlock
          </button>
        )}
      </div>
      {isAdmin && (
        <div>
          {/* Public saves cannot be deleted */}
          {save.publicSave && (
            <button
              className="bf-title-link public-save"
              title="You can't delete a public draft"
              disabled
            >
              Delete draft
            </button>
          )}
          {/* Saves being edited cannot be deleted */}
          {!save.publicSave && save.beingEdited && (
            <button
              className="bf-title-link public-save"
              title="You can't delete map being edited"
              disabled
            >
              Delete draft
            </button>
          )}
          {!save.publicSave && !save.beingEdited && (
            <button
              className="bf-title-link"
              onClick={() => {
                setShowDropdown(false);
                setDeleteDraftModalOpen(true);
              }}
              onBlur={() => handleOnBlur()}
            >
              Delete draft
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DraftDropdown;
