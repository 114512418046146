import React from "react";

import Departments from "../components/departments";
import FloorMenu from "../components/floorMenu";
import "../styles/css/sidemenu.css";

const SideMenu = ({
  placedEmployeesList,
  moveEmployee,
  draggingDummyEmployee,
  lockEmployees,
  draggingEmployee,
  setDraggingEmployee,
  draggingListEmployee,
  setDraggingListEmployee,
  findDesk,
  setSelectedDeskId,
  draggingToAddDesk,
  employeeDepartmentFromDeletedDesk,
  setEmployeeDepartmentFromDeletedDesk,
}) => {
  return (
    <div className="side-menu-container">
      <div className="logo-container ">
        <p> Seating </p>
      </div>
      <Departments
        placedEmployeesList={placedEmployeesList}
        moveEmployee={moveEmployee}
        draggingDummyEmployee={draggingDummyEmployee}
        lockEmployees={lockEmployees}
        draggingEmployee={draggingEmployee}
        setDraggingEmployee={setDraggingEmployee}
        draggingListEmployee={draggingListEmployee}
        setDraggingListEmployee={setDraggingListEmployee}
        findDesk={findDesk}
        setSelectedDeskId={setSelectedDeskId}
        draggingToAddDesk={draggingToAddDesk}
        employeeDepartmentFromDeletedDesk={employeeDepartmentFromDeletedDesk}
        setEmployeeDepartmentFromDeletedDesk={
          setEmployeeDepartmentFromDeletedDesk
        }
      />

      <FloorMenu />
    </div>
  );
};

export default SideMenu;
