import React, { useEffect, useRef, useState } from "react";

import { Badge, Button, Icon, Modal } from "@intility/bifrost-react";
import { authorizedFetch, useUser } from "@intility/react-msal-browser";
import { useHistory } from "react-router-dom";

import { useEmployee } from "../contexts/employeeContext";
import { useLoading } from "../contexts/loadingContext";
import { useSave } from "../contexts/saveContext";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { useRole } from "contexts/roleContext";

const EditSaveOptions = ({ setSelectedDeskId }) => {
  const user = useUser();
  const { setShowSpinner } = useLoading();
  const { isAdmin, isEditor } = useRole();
  const { employees, employeeIds } = useEmployee();
  const {
    currentSave,
    updateBasicSave,
    discardSaveChanges,
    editing,
    editingEmployeeId,
    revalidateEditingEmployeeId,
    revalidateSelectedSave,
    saveChanges,
    hasChanged,
    cleanUpRemovedEmployees,
  } = useSave();

  const history = useHistory();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const editOptionsRef = useRef();

  const getCancelConfirmationModal = () => {
    return (
      <Modal
        header="Discard changes?"
        isOpen={showCancelConfirmation}
        onRequestClose={() => setShowCancelConfirmation(false)}
      >
        <p className="modal-text">
          You have unsaved changes, are you sure you want to continue without
          saving?
        </p>
        <Modal.Footer>
          <div className="modal-btns-container">
            <Button
              onClick={() => {
                setShowCancelConfirmation(false);
              }}
            >
              Continue editing
            </Button>
            <Button
              variant="filled"
              onClick={() => {
                setShowCancelConfirmation(false);
                setSelectedDeskId(null);
                discardSaveChanges();
              }}
            >
              Discard changes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const [editingEmployee, setEditingEmployee] = useState();

  // Clicking edit button
  const handleClickEdit = async () => {
    setShowSpinner(true);

    const res = await authorizedFetch(
      `${process.env.REACT_APP_API_URL}/api/saves/${currentSave.id}`
    );
    const data = await res.json();
    if (data.beingEdited) {
      getEditingEmployee(data);
      setShowSpinner(false);
      revalidateEditingEmployeeId();
      return;
    }

    await revalidateSelectedSave();
    setSelectedDeskId(null);

    const id = user?.homeAccountId.split(".")[0];
    currentSave.beingEdited = true;
    currentSave.currentlyEditingEmployeeId = id;

    let promise = cleanUpRemovedEmployees(employeeIds);

    promise.then((value) => {
      if (value) {
        return;
      } else {
        updateBasicSave(currentSave);
      }
    });
  };

  // Get editing employee
  const getEditingEmployee = (save) => {
    const emp = employees.find((x) => x.id === save.currentlyEditingEmployeeId);
    setEditingEmployee(emp);
  };

  useEffect(() => {
    if (currentSave) {
      getEditingEmployee(currentSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSave]);

  const handleCancelClick = () => {
    if (!currentSave.id) {
      history.push("/dashboard");
      return;
    }
    if (hasChanged) {
      setShowCancelConfirmation(true);
    } else {
      setSelectedDeskId(null);
      setEditingEmployee(null);
      discardSaveChanges();
    }
  };

  return (
    <div className="edit-options">
      {!editing && editingEmployeeId && editingEmployee && (
        <Badge>
          <i>
            {editingEmployee.firstName} {editingEmployee.lastName}
          </i>{" "}
          is currently editing
        </Badge>
      )}
      {!editing && (isEditor || isAdmin) && (
        <Button
          stretch
          small
          variant="filled"
          className={
            editingEmployeeId
              ? "edit-options-button editing"
              : "edit-options-button"
          }
          onClick={() => {
            handleClickEdit();
          }}
          {...(editingEmployeeId && {
            variant: "outline",
            state: "inactive",
          })}
        >
          {editingEmployeeId && <Icon icon={faLock} />}
          Edit draft
        </Button>
      )}
      {editing && (
        <div ref={editOptionsRef}>
          <Button
            small
            className={"edit-options-button"}
            variant="outline"
            onClick={() => handleCancelClick()}
          >
            Cancel
          </Button>
          <Button
            small
            variant="filled"
            className={"edit-options-button"}
            onClick={() => {
              setEditingEmployee(null);
              setSelectedDeskId(null);
              saveChanges();
            }}
          >
            Save
          </Button>
          {showCancelConfirmation && getCancelConfirmationModal()}
        </div>
      )}
    </div>
  );
};

export default EditSaveOptions;
