import React, { useState, useEffect } from "react";

import {
  faCommentEdit,
  faCommentLines,
} from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@intility/bifrost-react";

import CommentModal from "./commentModal";
import EmployeeInfoCard from "./employeeInfoCard";
import EmployeePicture from "./employeePicture";
import "../styles/css/employee.css";
import "../styles/css/depColors.css";
import { useSave } from "contexts/saveContext";
import { usePopper } from "react-popper";

const DeskEmployee = ({
  employee,
  employeeComment,
  department,
  currentDesk,
  lockEmployees,
  setDraggingEmployee,
  selected,
  setSelectedDeskId,
  showCommentModal,
  setShowCommentModal,
  initSelected,
}) => {
  const { editing } = useSave();

  const [showCard, setShowCard] = useState(false);
  const element = document.querySelector(".grid-layout");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: element,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  const onDragStart = (event) => {
    if (editing) {
      event.dataTransfer.setData("employeeId", employee.id);
      event.dataTransfer.setData("currentDesk", JSON.stringify(currentDesk));
      setDraggingEmployee(true);
      event.target.id = "dragging";
    }
  };

  useEffect(() => {
    if (selected && !editing && initSelected) {
      let test = setTimeout(() => {
        setShowCard(true);
      }, 250);
      return () => clearTimeout(test);
    }
  }, [selected, editing, initSelected]);

  const getCard = () => {
    if (selected && !editing) {
      return (
        <EmployeeInfoCard
          ref={setPopperElement}
          styles={styles.popper}
          employee={employee}
          employeeComment={employeeComment}
          departmentColor={department?.color}
          setSelectedDeskId={setSelectedDeskId}
        />
      );
    }
  };

  //function to get the two first initials in the department name
  const getDepartmentInitials = () => {
    const departmentNameList = employee.department
      ? employee.department.split(" ")
      : [];
    let initials = "";

    for (let i = 0; i < departmentNameList.length && initials.length < 2; i++) {
      let initial = departmentNameList[i][0]?.toUpperCase();
      //excludes special characters
      if (initial >= "A" && initial <= "Z") {
        initials += initial;
      }
    }

    return initials || "?";
  };

  return (
    <>
      <div
        ref={setReferenceElement}
        className="desk-employee"
        draggable={editing && !lockEmployees}
        onDragStart={(event) => {
          onDragStart(event);
        }}
        onDragEnd={(event) => {
          event.target.removeAttribute("id");
          setDraggingEmployee(false);
        }}
      >
        <div
          className="employee-avatar-container"
          draggable={editing && !lockEmployees}
        >
          <div className="employee-img-container">
            <EmployeePicture employee={employee} />
          </div>
          <div
            className={`employee-department-badge ${
              department ? department.color : "yellow"
            }`}
          >
            <p>{getDepartmentInitials()}</p>
          </div>
        </div>
        <p className="employee-name">{employee.firstName}</p>
        {(!initSelected || (initSelected && showCard)) && getCard()}
      </div>
      {employeeComment && (
        <Icon
          icon={editing ? faCommentEdit : faCommentLines}
          className="desk-icon comment-icon"
          onMouseDown={(e) => !editing && e.stopPropagation()}
          onClick={() => {
            setSelectedDeskId(currentDesk.id);
            if (editing) {
              setShowCommentModal((x) => !x);
            }
          }}
        />
      )}

      {showCommentModal && selected && (
        <CommentModal
          employee={employee}
          employeeComment={employeeComment}
          showCommentModal={showCommentModal}
          setShowCommentModal={setShowCommentModal}
        />
      )}
    </>
  );
};

export default React.memo(DeskEmployee);
