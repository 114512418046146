import React from "react";

import "../styles/css/seatingHeader.css";
import { useLocation } from "../contexts/locationContext";
import FileSettingsMenu from "./fileSettingsMenu";
import { Badge } from "@intility/bifrost-react";

const SeatingHeader = ({
  removeAllEmployeesFromFloor,
  removeAllEmployees,
  deleteAllDesksFromFloor,
  deleteAllDesks,
  floorsTotalDesks,
  floorsTotalEmployees,
}) => {
  const { selectedLocation, selectedFloor, selectedFloorPart } = useLocation();

  const getFloorTitle = () => {
    let floorTitle = "";

    if (!selectedFloorPart.name) {
      floorTitle = `${selectedFloor?.floorNumber}. Floor (${selectedLocation?.name})`;
    } else {
      floorTitle = `${selectedFloor?.floorNumber}. Floor ${selectedFloorPart?.name} (${selectedLocation?.name})`;
    }
    return floorTitle;
  };

  return (
    <header className="seating-header-container">
      <div className="floor-title-container">
        <h1 className="floor-title">{getFloorTitle()}</h1>
        <Badge state="neutral">
          {`${
            floorsTotalDesks - floorsTotalEmployees
          } / ${floorsTotalDesks} desks available`}
        </Badge>
      </div>
      <FileSettingsMenu
        removeAllEmployeesFromFloor={removeAllEmployeesFromFloor}
        removeAllEmployees={removeAllEmployees}
        deleteAllDesks={deleteAllDesks}
        deleteAllDesksFromFloor={deleteAllDesksFromFloor}
      />
    </header>
  );
};

export default SeatingHeader;
