import React from "react";

import {
  faSignOutAlt,
  faPlus,
  faHandPointLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { Checkbox, Icon, Nav, useTheme } from "@intility/bifrost-react";
import { useAuth, useUser } from "@intility/react-msal-browser";
import { NavLink } from "react-router-dom";

import EditSaveOptions from "../components/editSaveOptions";
import ProfilePicture from "./profilePicture";
import "../styles/css/toolbar.css";
import { useRole } from "contexts/roleContext";
import { useSave } from "contexts/saveContext";

const ToolMenu = ({
  setSelectedDeskId,
  lockEmployees,
  setLockEmployees,
  setDraggingToAddDesk,
}) => {
  const { editing } = useSave();
  const { isAdmin, isEditor } = useRole();

  const { theme, setTheme } = useTheme();
  const user = useUser();
  const { logout } = useAuth();

  return (
    <>
      {editing && isAdmin && (
        <>
          <div className="add-desk-container">
            <div
              className="add-desk-icon-container"
              draggable={true}
              onDragStart={() => {
                setDraggingToAddDesk(true);
                setSelectedDeskId(null);
              }}
              onDragEnd={() => setDraggingToAddDesk(false)}
            >
              <div className="add-desk-icon">
                <Icon icon={faPlus}></Icon>
              </div>
              <div className="add-desk-icon-chair"> </div>
            </div>
            <p>
              <Icon icon={faHandPointLeft} /> Drag and drop desk to floor plan
            </p>
          </div>
        </>
      )}
      {editing && <h5>EDIT MODE</h5>}

      <div className="menu-options-container">
        {(isAdmin || isEditor) && (
          <>
            {editing && isAdmin && (
              <Checkbox
                className="lock-employees"
                label="Lock employees"
                checked={lockEmployees}
                onChange={() => setLockEmployees((x) => !x)}
              />
            )}

            <EditSaveOptions setSelectedDeskId={setSelectedDeskId} />
            {editing ? (
              <Nav.Item
                className="dashboard-btn editing"
                title="Save your project first"
              >
                Dashboard
              </Nav.Item>
            ) : (
              <NavLink className="dashboard-btn" to="/dashboard">
                <Nav.Item> Dashboard </Nav.Item>
              </NavLink>
            )}
          </>
        )}

        <Nav.Group
          className="profileDropdown"
          name={<ProfilePicture />}
          title={user.name}
        >
          <div className="bf-nav-header">Color theme</div>
          <Checkbox
            type="radio"
            label="Dark"
            name="color-theme"
            checked={theme === "dark"}
            onChange={() => setTheme("dark")}
          />
          <Checkbox
            type="radio"
            label="Light"
            name="color-theme"
            checked={theme === "light"}
            onChange={() => setTheme("light")}
          />
          <Checkbox
            type="radio"
            label="System"
            name="color-theme"
            checked={theme === "system"}
            onChange={() => setTheme("system")}
          />
          <a href="/#" onClick={() => logout()}>
            <Nav.Item icon={faSignOutAlt} hideCircle>
              Log out
            </Nav.Item>
          </a>
        </Nav.Group>
      </div>
    </>
  );
};

export default ToolMenu;
