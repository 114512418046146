import React, { useState, useMemo, useLayoutEffect } from "react";

import { faSmileWink } from "@fortawesome/pro-solid-svg-icons";
import { Checkbox, Icon } from "@intility/bifrost-react";

import { useEmployee } from "contexts/employeeContext";
import DepartmentEditMode from "../components/departmentEditMode";
import DepartmentViewMode from "../components/departmentViewMode";
import SearchField from "../components/searchField";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useSave } from "contexts/saveContext";

const Departments = ({
  placedEmployeesList,
  moveEmployee,
  draggingDummyEmployee,
  lockEmployees,
  draggingEmployee,
  setDraggingEmployee,
  draggingListEmployee,
  setDraggingListEmployee,
  findDesk,
  setSelectedDeskId,
  draggingToAddDesk,
  employeeDepartmentFromDeletedDesk: droppedEmployeeDepartment,
  setEmployeeDepartmentFromDeletedDesk: setDroppedEmployeeDepartment,
}) => {
  const { editing } = useSave();
  const { employees, departments } = useEmployee();

  const [showPlaced, setShowPlaced] = useState(true);
  const [searchResult, setSearchResult] = useState(employees);
  const [searchValue, setSearchValue] = useState("");
  const [openAll, setOpenAll] = useState(false);
  const [departmentScrollRef, setDepartmentScrollRef] = useState();

  useLayoutEffect(() => {
    setSearchValue("");
    setShowPlaced(true);
  }, [editing]);

  const departmentsWithEmployees = useMemo(() => {
    let departmentsWithEmployees = employees
      .sort((employeeA, employeeB) =>
        `${employeeA.firstName} ${employeeA.lastName}` >
        `${employeeB.firstName} ${employeeB.lastName}`
          ? 1
          : -1
      )
      .reduce(
        (acc, employee) => {
          let department = acc.find((dep) => dep.name === employee.department);

          //employee department not found
          if (!department) {
            department = acc.find((dep) => dep.name === "Other");

            //"Other" department not found
            if (!department) {
              department = { name: "Other", employees: [] };
              acc.push(department);
            }
          }

          department.employees.push(employee);
          return acc;
        },
        departments.map((dep) => ({
          name: dep.name,
          color: dep.color,
          employees: [],
        }))
      );

    return departmentsWithEmployees;
  }, [departments, employees]);

  const departmentsToShow = useMemo(
    () =>
      [
        ...(searchValue.length > 0 ? searchResult : departmentsWithEmployees),
      ].sort(function (departmentA, departmentB) {
        // Sorting departments with employees before empty departments
        if (!editing) {
          const aIsEmpty = departmentA.employees
            .map((employee) => placedEmployeesList.includes(employee.id))
            .includes(true);

          const bIsEmpty = departmentB.employees
            .map((employee) => placedEmployeesList.includes(employee.id))
            .includes(true);

          //sort departments with employees first in the list
          if (aIsEmpty && !bIsEmpty) {
            return -1;
          }

          //sort departments without employees last in the list
          if (bIsEmpty && !aIsEmpty) {
            return 1;
          }
        }

        if (editing && !showPlaced) {
          const aHasUnplaced = departmentA.employees
            .map((employee) => placedEmployeesList.includes(employee.id))
            .includes(false);

          const bHasUnplaced = departmentB.employees
            .map((employee) => placedEmployeesList.includes(employee.id))
            .includes(false);

          //sort departments with unplaced employees first in the list
          if (aHasUnplaced && !bHasUnplaced) {
            return -1;
          }

          //sort departments without unplaced employees last in the list
          if (bHasUnplaced && !aHasUnplaced) {
            return 1;
          }
        }

        //sort the department "Other" to the bottom
        if (departmentA.name === "Other") {
          return 1;
        }
        if (departmentB.name === "Other") {
          return -1;
        }

        //sort alphabetically
        return departmentA.name > departmentB.name ? 1 : -1;
      }),
    [
      placedEmployeesList,
      searchResult,
      departmentsWithEmployees,
      showPlaced,
      editing,
      searchValue.length,
    ]
  );

  const getDepartmentsWithEmployees = () => {
    if (searchValue.length > 0 && searchResult.length === 0) {
      return (
        <div className="employee-message-container">
          <p>{`We couldn't find any ${
            !showPlaced ? "unplaced " : ""
          }employees with the name '${searchValue}'..`}</p>
        </div>
      );
    } else if (
      !searchValue &&
      !showPlaced &&
      placedEmployeesList.length === employees.length
    ) {
      return (
        <div className="employee-message-container">
          <p>
            Good job <Icon icon={faSmileWink} />
          </p>
          <p>All employees have been placed.</p>
        </div>
      );
    } else {
      return departmentsToShow.map((department) =>
        editing ? (
          <DepartmentEditMode
            key={department.name}
            department={department}
            placedEmployeesList={placedEmployeesList}
            showPlaced={showPlaced}
            showSearchResults={searchValue.length > 0}
            setDraggingListEmployee={setDraggingListEmployee}
            setDraggingEmployee={setDraggingEmployee}
            employeeDropped={department.name === droppedEmployeeDepartment}
            setDroppedEmployeeDepartment={setDroppedEmployeeDepartment}
            lockEmployees={lockEmployees}
            setSelectedDeskId={setSelectedDeskId}
            openAll={openAll}
            setOpenAll={setOpenAll}
            findDesk={findDesk}
          />
        ) : (
          <DepartmentViewMode
            key={department.name}
            department={department}
            placedEmployeesList={placedEmployeesList}
            lockEmployees={lockEmployees}
            showSearchResults={searchValue.length > 0}
            findDesk={findDesk}
            openAll={openAll}
            setOpenAll={setOpenAll}
          />
        )
      );
    }
  };

  const addHoverEffect = (event) => {
    if (event.target.classList.contains("drop-zone")) {
      event.target.classList.add("hover");
    } else if (event.target.parentElement.classList.contains("drop-zone")) {
      event.target.parentElement.classList.add("hover");
    }
  };

  const removeHoverEffect = (event) => {
    if (event.target.classList.contains("drop-zone")) {
      event.target.classList.remove("hover");
    } else if (event.target.parentElement.classList.contains("drop-zone")) {
      event.target.parentElement.classList.remove("hover");
    }
  };

  const onDrop = (event) => {
    let oldDesk = JSON.parse(event.dataTransfer.getData("currentDesk"));
    let employeeId = event.dataTransfer.getData("employeeId");
    moveEmployee(null, employeeId, oldDesk);

    let newDroppedEmployeeDepartment = employees.find(
      (e) => e.id === employeeId
    ).department;

    if (!draggingListEmployee && searchValue.length > 0) {
      setSearchValue("");
    }

    setDraggingEmployee(false);
    setDraggingListEmployee(false);
    setDroppedEmployeeDepartment(newDroppedEmployeeDepartment);
  };

  const getDropZone = () => {
    return (
      <div
        className={`drop-zone-container ${
          draggingEmployee && !draggingListEmployee ? "" : "hide"
        }`}
        onDragOver={(event) => {
          addHoverEffect(event);
        }}
        onDragLeave={(event) => {
          removeHoverEffect(event);
        }}
      >
        <div className="drop-zone">
          <p className="drop-zone-text">
            Drop employee here to remove from desk
          </p>
        </div>
      </div>
    );
  };

  const [lastScrollPos, setLastScrollPos] = useState(0);
  const handleCollapseOpen = () => {
    if (!openAll) {
      if (!departmentScrollRef) return;
      setLastScrollPos(departmentScrollRef.scrollTop);
    }
    if (openAll) {
      if (!departmentScrollRef) return;
      departmentScrollRef.scrollTo(0, lastScrollPos);
    }
    setOpenAll((x) => !x);
  };

  return (
    <div
      className="employee-section"
      onDragOver={(event) => {
        if (!draggingToAddDesk && editing && !draggingDummyEmployee) {
          event.preventDefault();
        }
      }}
      onDrop={(event) => {
        if (draggingEmployee) {
          onDrop(event);
        }
      }}
    >
      <div className="filter-options">
        <SearchField
          departments={departmentsWithEmployees}
          placedEmployeesList={placedEmployeesList}
          showPlaced={showPlaced}
          setSearchResult={setSearchResult}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setOpenAll={setOpenAll}
        />

        <div className="filter-view-options">
          <p className="collapse-option" onClick={() => handleCollapseOpen()}>
            <Icon icon={openAll ? faMinus : faPlus} />
            {openAll ? "Collapse all" : "Expand all"}
          </p>
          {editing && (
            <div className="placed-options">
              <Checkbox
                label="All"
                type="radio"
                checked={showPlaced}
                onChange={() => setShowPlaced(true)}
              />
              <Checkbox
                label="Unplaced"
                type="radio"
                checked={!showPlaced}
                onChange={() => setShowPlaced(false)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="scrollbar-container bf-scrollbar-small"
        ref={(ref) => setDepartmentScrollRef(ref)}
      >
        <div className="departments-container">
          {getDepartmentsWithEmployees()}
        </div>
      </div>
      {getDropZone()}
    </div>
  );
};

export default Departments;
