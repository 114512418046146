import React, { createContext, useState, useContext } from "react";

const LoadingContext = createContext(null);
const useLoading = () => useContext(LoadingContext);

const LoadingProvider = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        showSpinner,
        setShowSpinner,
      }}
    >
      {props && props.children}
    </LoadingContext.Provider>
  );
};

export { useLoading };
export default LoadingProvider;
