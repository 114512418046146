import React, { useState, useEffect } from "react";

import ListEmployee from "../components/listEmployee";
import Department from "../components/department";

const DepartmentViewMode = ({
  department,
  placedEmployeesList,
  showSearchResults,
  findDesk,
  openAll,
}) => {
  const [numberOfPlacedEmployees, setNumberOfPlacedEmployees] = useState(0);
  const [open, setOpen] = useState(false);

  // Oopen if showSearchResults
  useEffect(() => {
    if (showSearchResults) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showSearchResults]);

  // Open or close all
  useEffect(() => {
    if (openAll) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openAll]);

  useEffect(() => {
    setNumberOfPlacedEmployees(
      department.employees.filter((employee) =>
        placedEmployeesList.includes(employee.id)
      ).length
    );
  }, [department.employees, placedEmployeesList]);

  const getEmployees = (employees) => {
    return employees.map((employee) => {
      const placed = placedEmployeesList.includes(employee.id);
      if (placed) {
        return (
          <ListEmployee
            key={employee.id}
            employee={employee}
            placed={placed}
            currentDesk={null}
            showPlaced={true}
            findDesk={findDesk}
          />
        );
      }
      return null;
    });
  };

  return (
    <Department
      department={department}
      employees={getEmployees(department.employees)}
      showPlaced={false}
      numberOfEmployees={numberOfPlacedEmployees}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default DepartmentViewMode;
