import React, { useEffect, useCallback } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input } from "@intility/bifrost-react";

import { useSave } from "contexts/saveContext";

const SearchField = ({
  departments,
  placedEmployeesList,
  showPlaced,
  setSearchResult,
  searchValue,
  setSearchValue,
  setOpenAll,
}) => {
  const { editing } = useSave();

  const updateSearchResult = useCallback(() => {
    if (searchValue) {
      const searchedDepartments = departments.map((department) => {
        let matchingEmployees = department.employees.filter((employee) =>
          `${employee.firstName} ${employee.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );

        if (!editing) {
          matchingEmployees = matchingEmployees.filter((employee) =>
            placedEmployeesList.includes(employee.id)
          );
        } else if (editing && !showPlaced) {
          matchingEmployees = matchingEmployees.filter(
            (employee) => !placedEmployeesList.includes(employee.id)
          );
        }

        return { ...department, employees: [...matchingEmployees] };
      });

      const searchResult = searchedDepartments.filter(
        (department) => department.employees.length > 0
      );
      setSearchResult(searchResult);
    } else {
      setSearchResult(departments);
    }
  }, [
    editing,
    departments,
    placedEmployeesList,
    setSearchResult,
    showPlaced,
    searchValue,
  ]);

  // Open or close all departments if the search field has value or not
  useEffect(() => {
    if (searchValue) {
      setOpenAll(true);
    } else {
      setOpenAll(false);
    }
  }, [searchValue, setOpenAll]);

  // When writing in searchfield
  useEffect(() => {
    updateSearchResult();
  }, [searchValue, showPlaced, updateSearchResult]);

  return (
    <div className="search-field-container">
      <Input
        label="search"
        hideLabel
        clearable
        icon={faSearch}
        variant="outline"
        placeholder="Search for employee"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default SearchField;
