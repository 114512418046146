import { useEffect } from "react";

const useKeyPress = (key, handler, specialKey) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.target.tagName === "INPUT" ||
        e.target.tagName === "TEXTAREA" ||
        e.repeat
      )
        return;
      if (
        e.key.toLowerCase() === key &&
        (!specialKey || e[specialKey + "Key"])
      ) {
        handler();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handler, key, specialKey]);
};

export default useKeyPress;
