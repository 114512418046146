import React, { useEffect, useState } from "react";

import { faCircle, faUser } from "@fortawesome/pro-solid-svg-icons";
import { Card, Icon } from "@intility/bifrost-react";

import "../styles/css/card.css";

const EmployeeInfoCard = React.forwardRef(
  ({ employee, employeeComment, departmentColor, styles }, ref) => {
    const prettyPhoneNumber = (nr) => {
      if (nr.length === 11) {
        return nr?.replace(/(\d{2})(\d{3})(\d{2})/, "$1 $2 $3 ");
      }
    };

    const [imgExists, setImgExists] = useState(false);

    useEffect(() => {
      if (!employee) return;
      const url = employee.pictureUri;
      let image = new Image();
      image.src = url;

      image.onload = () => {
        setImgExists(true);
      };
    }, [employee]);

    return (
      <div
        ref={ref}
        style={styles}
        className={`employee-card`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Card>
          <div
            className={`employee-card-header`}
            style={{
              backgroundColor: `var(--${departmentColor}-50)`,
            }}
          />
          {imgExists ? (
            <Card.Logo imageUrl={employee.pictureUri} />
          ) : (
            <Card.Logo icon={faUser} />
          )}
          <Card.Title>
            {employee.firstName} {employee.lastName}
          </Card.Title>
          <p>{employee.department}</p>
          <p>{employee.jobTitle}</p>
          <Card.Content>
            <div className="contact-info">
              <p>
                <span>E-mail: </span>
                <a className="bf-link" href={`mailto: ${employee.email}`}>
                  {employee.email}
                </a>
              </p>
              <p>
                <span>Mobile: </span>
                {prettyPhoneNumber(employee.mobile)}
              </p>
            </div>
            {employeeComment && (
              <div className="comment-container bf-content">
                <p>
                  <Icon icon={faCircle} />
                  Comment:
                </p>
                {employeeComment.title && <p>{employeeComment.title}</p>}
                {employeeComment.text && <p>{employeeComment.text}</p>}
              </div>
            )}
          </Card.Content>
        </Card>
      </div>
    );
  }
);

export default EmployeeInfoCard;
