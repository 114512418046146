import React, { useEffect, useState } from "react";

import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@intility/bifrost-react";
import { authorizedFetch, useUser } from "@intility/react-msal-browser";

// Getting photo from graph
const getPhoto = async (setImgUrl) => {
  try {
    const res = await authorizedFetch(
      "https://graph.microsoft.com/v1.0/me/photo/$value"
    );
    if (res.status !== 200) return;
    if (res.status === 200) {
      const data = await res.blob();
      setImgUrl(URL.createObjectURL(data));
    }
  } catch (e) {
    console.log(e);
  }
};

// Returning img element with either logged in user photo or a fallback usericon
const ProfilePicture = () => {
  const user = useUser();
  const [imgUrl, setImgUrl] = useState();

  useEffect(() => {
    getPhoto(setImgUrl);
  }, [user]);

  if (!imgUrl)
    return (
      <div className="profile-img-container employee-picture">
        <Icon icon={faUser} />
      </div>
    );

  return (
    <div className="profile-img-container">
      <img
        alt={(user && user.name) || "profilepicture"}
        className="profile-img"
        src={imgUrl}
      />
    </div>
  );
};

export default ProfilePicture;
