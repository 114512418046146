import React from "react";

import { Button, Modal } from "@intility/bifrost-react";
import { useSave } from "contexts/saveContext";

const UnlockDraftModal = ({
  open,
  setOpen,
  save,
  editingEmployee,
  timeEdited,
}) => {
  const { discardSaveChanges } = useSave();

  const handleUnlockDraft = () => {
    discardSaveChanges(save);
    setOpen(false);
  };

  return (
    <Modal
      className="unlock-draft-modal"
      header={`Unlock '${save.name}'`}
      isOpen={open}
      onRequestClose={() => setOpen(false)}
    >
      <p className="bf-p">
        {editingEmployee} started editing {timeEdited}.
      </p>

      <p className="bf-p">
        Are you sure you want to unlock this draft?{" "}
        <b>Changes {editingEmployee} made to this draft will be lost. </b>
      </p>

      <Modal.Footer>
        <div className="buttons-container">
          <Button onClick={() => setOpen(false)}>Cancel</Button>

          <Button variant="filled" onClick={() => handleUnlockDraft()}>
            Unlock
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UnlockDraftModal;
