export const departmentColors = [
  "yellow",
  "red",
  "light-purple",
  "purple",
  "cyan",
  "dark-cyan",
  "gray",
  "dark-green",
  "white",
  "light-pink",
  "blue",
  "pink",
  "black",
  "light-green",
];
