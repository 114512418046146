import React, { useEffect, useState } from "react";

import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@intility/bifrost-react";

import "../styles/css/employeePicture.css";

const EmployeePicture = ({ employee }) => {
  const [imgExists, setImgExists] = useState(false);

  useEffect(() => {
    if (!employee) return;
    const url = employee.pictureUri;
    let image = new Image();
    image.src = url;

    image.onload = () => {
      setImgExists(true);
    };
  }, [employee]);

  return (
    <div className="employee-picture">
      {imgExists ? (
        <img alt={employee?.name} src={employee?.pictureUri} />
      ) : (
        <Icon icon={faUser} />
      )}
    </div>
  );
};

export default EmployeePicture;
