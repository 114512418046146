import React from "react";

import {
  faCommentLines,
  faRedoAlt,
  faTrash,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Modal } from "@intility/bifrost-react";
import { useRole } from "contexts/roleContext";

const ToolBox = React.forwardRef(
  (
    {
      desk,
      styles,
      rotateDesk,
      deleteDesk,
      setSelectedDeskId,
      showEditDummyModal,
      setShowEditDummyModal,
      showDeleteDeskConfirmation,
      setShowDeleteDeskConfirmation,
      setShowCommentModal,
    },
    ref
  ) => {
    const { isAdmin } = useRole();

    const deleteDeskBtnClick = () => {
      if (desk.employeeId || desk.dummyEmployee) {
        setShowDeleteDeskConfirmation(true);
      } else {
        deleteDesk(desk, desk.employeeId ? desk.employeeId : null);
      }
    };

    const getDeleteDeskConfirmationModal = () => {
      return (
        <Modal
          header="Delete assigned desk?"
          isOpen={showDeleteDeskConfirmation}
          onRequestClose={() => setShowDeleteDeskConfirmation(false)}
        >
          <p className="modal-text">{`Are you sure you want to delete this desk? 
          ${
            desk.dummyEmployee
              ? "The dummy employee will also be deleted."
              : "The employee will be moved back to the department list."
          }`}</p>
          <Modal.Footer>
            <div className="modal-btns-container">
              <Button
                onClick={() => {
                  setShowDeleteDeskConfirmation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedDeskId(null);
                  setShowDeleteDeskConfirmation(false);
                  deleteDesk(desk, desk.employeeId ? desk.employeeId : null);
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <>
        <div ref={ref} style={styles} className="desk-tools-container">
          {isAdmin && (
            <>
              <Icon
                icon={faRedoAlt}
                title="Rotate desk"
                className="desk-tool-btn"
                onClick={() => rotateDesk(desk)}
                onKeyDown={(e) => e.key === "Enter" && rotateDesk(desk)}
                tabIndex="0"
              />
              <Icon
                icon={faTrash}
                title="Delete desk"
                className="desk-tool-btn"
                onClick={() => deleteDeskBtnClick()}
                onKeyDown={(e) => e.key === "Enter" && deleteDeskBtnClick()}
                tabIndex="0"
              />
            </>
          )}
          {desk.employeeId ? (
            <Icon
              icon={faCommentLines}
              title="Add comment to desk"
              className="desk-tool-btn"
              onClick={() => setShowCommentModal(true)}
              onKeyDown={(e) => e.key === "Enter" && setShowCommentModal(true)}
              tabIndex="0"
            />
          ) : (
            <Icon
              icon={faUserPlus}
              title="Add dummy employee to desk"
              className="desk-tool-btn"
              onClick={() => setShowEditDummyModal(!showEditDummyModal)}
              onKeyDown={(e) =>
                e.key === "Enter" && setShowEditDummyModal(!showEditDummyModal)
              }
              tabIndex="0"
            />
          )}
        </div>
        {showDeleteDeskConfirmation && getDeleteDeskConfirmationModal()}
      </>
    );
  }
);

export default ToolBox;
