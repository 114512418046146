import React, { useState } from "react";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown, Icon, Modal, Button } from "@intility/bifrost-react";

import { useLocation } from "../contexts/locationContext";
import { useSave } from "../contexts/saveContext";
import { useRole } from "contexts/roleContext";

const FileSettingsMenu = ({
  removeAllEmployeesFromFloor,
  removeAllEmployees,
  deleteAllDesksFromFloor,
  deleteAllDesks,
}) => {
  const { isAdmin, isEditor } = useRole();
  const { currentSave, deleteDraft, editing } = useSave();
  const { selectedLocation, selectedFloor, selectedFloorPart } = useLocation();
  const [actionClicked, setActionClicked] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const getActionConfirmationModal = () => {
    const actionContent = getActionContent();
    return (
      actionContent && (
        <Modal
          header={actionContent.title}
          isOpen={actionClicked ? true : false}
          onRequestClose={() => setActionClicked("")}
        >
          <p className="modal-text">{actionContent.contentText}</p>
          <Modal.Footer>
            <div className="modal-btns-container">
              <Button onClick={() => setActionClicked("")}>Cancel</Button>
              <Button
                variant="filled"
                onClick={() => {
                  actionContent.confirmClick();
                }}
              >
                {actionContent.confirmButtonText}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )
    );
  };

  const getActionContent = () => {
    let actionContent;

    switch (actionClicked) {
      case "removeEmployees":
        actionContent = {
          title: `Remove all employees from ${selectedFloor.floorNumber}. Floor ${selectedFloorPart.name}?`,
          contentText: `Are you sure you want to remove all the employees from ${selectedFloor.floorNumber}. Floor ${selectedFloorPart.name}? The employees will be moved back to the department list and all dummy employees will be deleted.`,
          confirmButtonText: "Remove employees",
          confirmClick: () => {
            removeAllEmployeesFromFloor();
            setActionClicked("");
          },
        };
        break;
      case "removeAllEmployees":
        actionContent = {
          title: `Remove all employees from ${selectedLocation.name}?`,
          contentText: `Are you sure you want to remove all the employees from ${selectedLocation.name}? All employees will be moved back to the department list and all dummy employees will be deleted.`,
          confirmButtonText: "Remove employees",
          confirmClick: () => {
            removeAllEmployees();
            setActionClicked("");
          },
        };
        break;
      case "deleteDesks":
        actionContent = {
          title: `Delete all desks from ${selectedFloor.floorNumber}. Floor ${selectedFloorPart.name}?`,
          contentText: `Are you sure you want to delete all desks from ${selectedFloor.floorNumber}. Floor ${selectedFloorPart.name}? All desks will be deleted, any placed employees will be moved back to the department list and all dummy employees on the floor will be deleted.`,
          confirmButtonText: "Delete desks",
          confirmClick: () => {
            deleteAllDesksFromFloor();
            setActionClicked("");
          },
        };
        break;
      case "deleteAllDesks":
        actionContent = {
          title: `Delete all desks from ${selectedLocation.name}?`,
          contentText: `Are you sure you want to delete all the desks from ${selectedLocation.name}? All desks will be deleted, any placed employees will be moved back to the department list and all dummy employees will be deleted.`,
          confirmButtonText: "Delete desks",
          confirmClick: () => {
            deleteAllDesks();
            setActionClicked("");
          },
        };
        break;
      case "deleteDraft":
        actionContent = {
          title: `Delete ${currentSave.name}?`,
          contentText: `Are you sure you want to delete the draft, ${currentSave.name}? This cannot be undone.`,
          confirmButtonText: "Delete draft",
          confirmClick: () => {
            deleteDraft(currentSave.id);
            setActionClicked("");
          },
        };
        break;
      default:
        setActionClicked("");
        console.log("Error - unknown value");
    }

    return actionContent;
  };

  const getActionDropdownButton = () => {
    return (
      <Dropdown
        content={dropDownContent}
        placement="bottom"
        visible={showDropdown}
        onClickOutside={() => setShowDropdown(false)}
      >
        <button
          className="action-dropdown-btn save-title bf-title-link"
          title={currentSave.name}
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          {currentSave.name} {currentSave.publicSave && "(public)"}
          <Icon icon={faAngleDown} />
        </button>
      </Dropdown>
    );
  };

  const dropDownContent = (
    <>
      {isAdmin && (
        <div className="action-section">
          <button
            className="action bf-title-link"
            onClick={() => {
              setShowDropdown(false);
              setActionClicked("deleteDesks");
            }}
          >
            <p>
              Remove all desks from{" "}
              <span>
                {selectedFloor.floorNumber}. Floor {selectedFloorPart.name}
              </span>
            </p>
            <Icon icon={faTrash}></Icon>
          </button>
          <button
            className="action bf-title-link"
            onClick={() => {
              setShowDropdown(false);
              setActionClicked("deleteAllDesks");
            }}
          >
            <p>
              Remove all desks from <span>{selectedLocation.name}</span>
            </p>
            <Icon icon={faTrash}></Icon>
          </button>
        </div>
      )}
      <div className="action-section">
        <button
          className="action bf-title-link"
          onClick={() => {
            setShowDropdown(false);
            setActionClicked("removeEmployees");
          }}
        >
          <p>
            Remove all employees from{" "}
            <span>
              {selectedFloor.floorNumber}. Floor {selectedFloorPart.name}
            </span>
          </p>
          <Icon icon={faTrash}></Icon>
        </button>
        <button
          className="action bf-title-link"
          onClick={() => {
            setShowDropdown(false);
            setActionClicked("removeAllEmployees");
          }}
        >
          <p>
            Remove all employees from <span>{selectedLocation.name}</span>
          </p>
          <Icon icon={faTrash}></Icon>
        </button>
      </div>
      {isAdmin && (
        <div className="action-section">
          {currentSave.publicSave && (
            <button
              className="bf-title-link action disabled"
              title={"You can't delete a public draft"}
              disabled
            >
              Delete draft
              <Icon icon={faTrash}></Icon>
            </button>
          )}
          {!currentSave.publicSave && (
            <button
              className="bf-title-link action"
              onClick={() => {
                setShowDropdown(false);
                setActionClicked("deleteDraft");
              }}
            >
              Delete draft
              <Icon icon={faTrash}></Icon>
            </button>
          )}
        </div>
      )}
    </>
  );

  const getSaveName = () => {
    return (
      <p className="save-title" title={currentSave.name}>
        {currentSave.name} {currentSave.publicSave && "(public)"}
      </p>
    );
  };

  return (
    <>
      {(isAdmin || isEditor || !currentSave.publicSave) && (
        <div className="save-name-container">
          {editing ? getActionDropdownButton() : getSaveName()}
        </div>
      )}
      {actionClicked && getActionConfirmationModal()}
    </>
  );
};

export default FileSettingsMenu;
