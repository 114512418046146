import React from "react";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Nav, useTheme } from "@intility/bifrost-react";
import { useAuth, useUser } from "@intility/react-msal-browser";
import { NavLink } from "react-router-dom";

import ProfilePicture from "../profilePicture";

const DashboardHeader = () => {
  const { theme, setTheme } = useTheme();
  const { logout } = useAuth();
  const user = useUser();

  return (
    <>
      <NavLink to="/dashboard">
        <Nav.Item> Dashboard </Nav.Item>
      </NavLink>

      <Nav.Group
        className="profileDropdown"
        name={<ProfilePicture />}
        title={user.name}
      >
        <div className="bf-nav-header">Color theme</div>
        <Checkbox
          type="radio"
          label="Dark"
          name="color-theme"
          checked={theme === "dark"}
          onChange={() => setTheme("dark")}
        />
        <Checkbox
          type="radio"
          label="Light"
          name="color-theme"
          checked={theme === "light"}
          onChange={() => setTheme("light")}
        />
        <Checkbox
          type="radio"
          label="System"
          name="color-theme"
          checked={theme === "system"}
          onChange={() => setTheme("system")}
        />
        <a href="/#" onClick={() => logout()}>
          <Nav.Item icon={faSignOutAlt} hideCircle>
            Log out
          </Nav.Item>
        </a>
      </Nav.Group>
    </>
  );
};

export default DashboardHeader;
