import React from "react";

import { Modal, useTheme } from "@intility/bifrost-react";
import "@intility/bifrost-react/dist/bifrost-app.css";
import MsalProvider, { authorizedFetch } from "@intility/react-msal-browser";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SWRConfig } from "swr";

import EmployeeProvider from "./contexts/employeeContext";
import LoadingProvider from "contexts/loadingContext";
import LocationProvider from "./contexts/locationContext";
import SaveProvider from "contexts/saveContext";
import RoleProvider from "contexts/roleContext";

import Dashboard from "../src/pages/dashboard";
import ReactGridLayoutPage from "../src/pages/reactGridLayoutPage";
import "./App.css";

const config = {
  auth: {
    clientId: "5ba6453b-d081-4765-a7ee-0ef4553cc19b",
    authority:
      "https://login.microsoftonline.com/9b5ff18e-53c0-45a2-8bc2-9c0c8f60b2c6",
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  endpoints: {
    "https://graph.microsoft.com": ["User.Read"],
    [process.env.REACT_APP_API_URL]: [
      "api://ba537e07-c258-4969-a410-c5ea5f59a808/Read",
    ],
  },
};

const fetcher = async (url) => {
  const res = await authorizedFetch(url);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

function App() {
  const { theme } = useTheme();
  Modal.setAppElement("#root");

  return (
    <MsalProvider config={config} forced>
      <SWRConfig value={{ fetcher }}>
        <div className="App">
          <BrowserRouter>
            <LoadingProvider>
              <RoleProvider>
                <Switch>
                  <Route path="/dashboard">
                    <SaveProvider>
                      <LocationProvider>
                        <EmployeeProvider>
                          <Dashboard />
                        </EmployeeProvider>
                      </LocationProvider>
                    </SaveProvider>
                  </Route>
                  <Route path="/:draftId?">
                    <SaveProvider>
                      <LocationProvider>
                        <EmployeeProvider>
                          <ReactGridLayoutPage />
                        </EmployeeProvider>
                      </LocationProvider>
                    </SaveProvider>
                  </Route>
                </Switch>
              </RoleProvider>
            </LoadingProvider>
          </BrowserRouter>
        </div>
      </SWRConfig>
    </MsalProvider>
  );
}

export default App;
