import React, { useState, useEffect } from "react";

import isEqual from "react-fast-compare";
import DeskEmployee from "../components/deskEmployee";
import DummyEmployee from "./dummyEmployee";
import ToolBox from "./toolBox";
import { useRole } from "contexts/roleContext";
import { useSave } from "contexts/saveContext";
import { usePopper } from "react-popper";

const Desk = ({
  desk,
  updateDesk,
  employee,
  employeeComment,
  department,
  updateEmployee,
  selected,
  onDragEmployeeOver,
  onDropEmployee,
  onDropDummyEmployee,
  draggingEmployee,
  draggingDummyEmployee,
  setDraggingDummyEmployee,
  setDraggingEmployee,
  lockEmployees,
  selectedDeskId,
  setSelectedDeskId,
  addDummyEmployeeActive,
  setAddDummyEmployeeActive,
  draggingDesk,
  draggingToAddDesk,
  rotateDesk,
  deleteDesk,
  showDeleteDeskConfirmation,
  setShowDeleteDeskConfirmation,
  initSelected,
  ...props
}) => {
  const { editing } = useSave();
  const { isEditor } = useRole();

  const [showEditDummyModal, setShowEditDummyModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  useEffect(() => {
    if (selected && addDummyEmployeeActive && !desk.employeeId) {
      setAddDummyEmployeeActive(false);
      setShowEditDummyModal(true);
    }
  }, [
    selected,
    desk.employeeId,
    addDummyEmployeeActive,
    setAddDummyEmployeeActive,
  ]);

  const getSelectedDeskStyle = () => {
    if (selected) {
      return { zIndex: 100 };
    }
  };

  const getDeskWithDummyStyling = () => {
    if (desk.dummyEmployee || showEditDummyModal) {
      return { display: "flex", justifyContent: "center" };
    }
  };

  const addHoverEffect = (event) => {
    const emptyDesk =
      !desk.employeeId && event.target.classList.contains("desk");
    if (
      editing &&
      !draggingToAddDesk &&
      event.target.classList.contains("desk")
    ) {
      event.target.classList.add("hover");
    }
  };

  const removeHoverEffect = (event) => {
    if (event.target.classList.contains("desk")) {
      event.target.classList.remove("hover");
    }
  };

  const element = document.querySelector(".grid-layout");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "top-start",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            boundary: element,
          },
        },
      ],
    }
  );
  useEffect(() => {
    if (!draggingDesk) {
      let interval = setInterval(update, 16);
      setTimeout(() => clearTimeout(interval), 250);
    }
  }, [draggingDesk, update]);
  return (
    <>
      <div
        {...props}
        ref={setReferenceElement}
        className={`${props.className} desk-container ${
          desk.rotated ? `rotated-${desk.rotated}` : ""
        } `}
        style={{ ...props.style, ...getSelectedDeskStyle() }}
        draggable={false}
        onDragEnter={(event) => {
          addHoverEffect(event);
        }}
        onDragOver={(event) => {
          if (!draggingToAddDesk) {
            if (editing) {
              onDragEmployeeOver(event);
            }
          }
        }}
        onDragLeave={(event) => {
          removeHoverEffect(event);
        }}
        onDrop={(event) => {
          if (!draggingToAddDesk) {
            if (draggingEmployee && !draggingDummyEmployee) {
              onDropEmployee(event, desk);
            } else if (draggingDummyEmployee) {
              onDropDummyEmployee(event, desk);
            }
            removeHoverEffect(event);
          }
        }}
      >
        <div
          className={`desk${selected ? " selected" : ""}${
            !editing && (!desk.employeeId || !employee) ? " empty" : ""
          }${isEditor ? " editor" : ""}`}
          style={getDeskWithDummyStyling()}
          onMouseDown={() => {
            if (editing) {
              setSelectedDeskId(desk.id);
            } else if (desk.employeeId && employee) {
              setSelectedDeskId(selectedDeskId === desk.id ? null : desk.id);
            }
          }}
          title={
            desk.employeeId &&
            employee &&
            `${employee?.firstName} ${employee?.lastName}`
          }
        >
          {desk.employeeId && employee && (
            <DeskEmployee
              employee={employee}
              department={department}
              updateEmployee={updateEmployee}
              employeeComment={employeeComment}
              currentDesk={desk}
              lockEmployees={lockEmployees}
              setDraggingEmployee={setDraggingEmployee}
              selected={selected}
              setSelectedDeskId={setSelectedDeskId}
              setShowCommentModal={setShowCommentModal}
              showCommentModal={showCommentModal}
              rotateDesk={rotateDesk}
              deleteDesk={deleteDesk}
              initSelected={initSelected}
            />
          )}
          {(desk.dummyEmployee || showEditDummyModal) && (
            <DummyEmployee
              desk={desk}
              updateDesk={updateDesk}
              showEditDummyModal={showEditDummyModal}
              setShowEditDummyModal={setShowEditDummyModal}
              draggingDummyEmployee={draggingDummyEmployee}
              setDraggingDummyEmployee={setDraggingDummyEmployee}
              lockEmployees={lockEmployees}
              deleteDesk={deleteDesk}
              rotateDesk={rotateDesk}
              selected={selected}
              addDummyEmployeeActive={addDummyEmployeeActive}
              setAddDummyEmployeeActive={setAddDummyEmployeeActive}
              setSelectedDeskId={setSelectedDeskId}
            />
          )}
        </div>
        <div className="chair"></div>
      </div>
      {editing && selected && !draggingDesk && (
        <ToolBox
          ref={setPopperElement}
          styles={styles.popper}
          desk={desk}
          rotateDesk={rotateDesk}
          deleteDesk={deleteDesk}
          setSelectedDeskId={setSelectedDeskId}
          showEditDummyModal={showEditDummyModal}
          setShowEditDummyModal={setShowEditDummyModal}
          setShowCommentModal={setShowCommentModal}
          showDeleteDeskConfirmation={showDeleteDeskConfirmation}
          setShowDeleteDeskConfirmation={setShowDeleteDeskConfirmation}
        />
      )}
    </>
  );
};

export default React.memo(Desk, isEqual);
