import React, { useState } from "react";
import { Message } from "@intility/bifrost-react";

function ErrorMessage({ error }) {
  const [showError, setShowError] = useState(true);

  if (!showError) return <></>;

  return (
    <div className="error-message-container">
      <Message
        state={"warning"}
        header={`Error: ${error?.status}`}
        onClose={() => setShowError(false)}
      >
        {error?.message} {error?.info?.title} {error?.info?.errors?.id}
      </Message>
    </div>
  );
}

export default ErrorMessage;
