import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth, useUser } from "@intility/react-msal-browser";

const RoleContext = createContext(null);

const useRole = () => {
  return useContext(RoleContext);
};

const RoleProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);

  // IF USER HAS WRITER RIGHTS AND IS INTILITY EMPLOYEE, SET AS ADMIN
  const user = useUser();
  const { acquireTokenSilent } = useAuth();
  useEffect(() => {
    if (user) {
      acquireTokenSilent([
        "api://ba537e07-c258-4969-a410-c5ea5f59a808/Read",
      ]).then((token) => {
        const tokenData = parseJwt(token.accessToken);
        if (tokenData["roles"]) {
          if (
            tokenData["roles"].includes(`${process.env.REACT_APP_ROLE_ADMIN}`)
          ) {
            setIsAdmin(true);
          } else if (
            tokenData["roles"].includes(`${process.env.REACT_APP_ROLE_EDITOR}`)
          ) {
            setIsEditor(true);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  return (
    <RoleContext.Provider value={{ isAdmin, isEditor }}>
      {children}
    </RoleContext.Provider>
  );
};

export { useRole };
export default RoleProvider;
