import React, { useEffect, useState } from "react";

import { Button, Modal, Select } from "@intility/bifrost-react";

import { useSave } from "contexts/saveContext";
import "../../styles/css/dashboard.css";

const MapSelect = () => {
  const { saves, updateBasicSave } = useSave();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [publicSave, setPublicSave] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!saves) return;

    // Dont update data unless selected draft has change to being edited
    if (selected) {
      const currentSave = saves.find((x) => x.id === selected.value.id);
      if (selected.value !== publicSave && !currentSave.beingEdited) return;
    }

    const opt = [];
    saves.forEach((save) => {
      if (save.publicSave) {
        setSelected({ value: save, label: save.name });
        setPublicSave(save);
      }
      const name = save.beingEdited ? `${save.name} (being edited)` : save.name;
      opt.push({ value: save, label: name, isDisabled: save.beingEdited });
    });
    setOptions(opt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saves]);

  // Clicking save button
  const handleSaveMap = () => {
    if (selected.value === publicSave) {
      setShowModal(false);
      return;
    }
    const newSave = { ...selected.value, publicSave: true };
    updateBasicSave(newSave);
    setSelected(null);
    setPublicSave(newSave);
    setShowModal(false);
  };

  return (
    <>
      <div className="map-select-container">
        <h5 className="map-select-header bf-h5"> Public draft: </h5>
        <p className="map-select-public-map bf-p">{publicSave?.name}</p>
        <Button onClick={() => setShowModal(true)}>Change public draft</Button>
      </div>

      {showModal && (
        <Modal
          className="select-public-map-modal"
          header="Change public draft"
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
        >
          <Select
            label="Select public draft"
            description="Public for all users, draft name is not visible"
            options={options}
            value={selected}
            onChange={(option) => {
              setSelected(option);
            }}
            menuPortalTarget={document.body}
          />
          <Modal.Footer>
            <div className="buttons-container">
              <Button onClick={() => setShowModal(false)}>Cancel</Button>
              <Button variant="filled" onClick={() => handleSaveMap()}>
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default MapSelect;
