import React from "react";

import { Icon } from "@intility/bifrost-react";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import "../styles/css/depColors.css";

const Department = ({
  department,
  employees,
  numberOfEmployees,
  showPlaced,
  open,
  setOpen,
}) => {
  return (
    <div
      className={`department-container ${
        numberOfEmployees === 0 && !showPlaced ? "empty" : ""
      }`}
    >
      <div
        className={`department-header ${open ? "open" : ""}`}
        onClick={() => {
          if (numberOfEmployees !== 0 || showPlaced) {
            setOpen(!open);
          }
        }}
      >
        <div className="department-name-container">
          <Icon
            icon={
              open || (open && numberOfEmployees !== 0 && !showPlaced)
                ? faAngleDown
                : faAngleRight
            }
          />
          <p>
            {department.name} <span>({numberOfEmployees})</span>
          </p>
        </div>
        <div
          className={`department-icon ${
            department.color ? department.color : "yellow"
          }`}
        ></div>
      </div>
      {open && employees}
    </div>
  );
};

export default Department;
