import React from "react";

import { Icon, useNav } from "@intility/bifrost-react";

import EmployeePicture from "./employeePicture";
import "../styles/css/employee.css";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useSave } from "contexts/saveContext";

const ListEmployee = ({
  employee,
  placed,
  currentDesk,
  showPlaced,
  findDesk,
  setDraggingListEmployee,
  setDraggingEmployee,
  setSelectedDeskId,
}) => {
  const { editing } = useSave();
  const onDragStart = (event) => {
    setDraggingListEmployee(true);
    setDraggingEmployee(true);
    setSelectedDeskId(null);
    event.dataTransfer.setData("employeeId", employee.id);
    event.dataTransfer.setData("currentDesk", JSON.stringify(currentDesk));
  };

  const { setMobileOpen } = useNav();

  const getNonDraggableStyle = () => {
    if (!editing) {
      return { cursor: "pointer" };
    }
    return {};
  };

  return (
    <div
      className={`list-employee ${
        placed && showPlaced && editing ? "placed" : ""
      }`}
      style={getNonDraggableStyle()}
      draggable={!placed && editing}
      onDragStart={(event) => {
        onDragStart(event);
      }}
      onDragEnd={() => {
        setDraggingEmployee(false);
        setDraggingListEmployee(false);
      }}
      onClick={() => {
        if (!editing || placed) {
          findDesk(employee.id);
        }
        if (!editing) {
          setMobileOpen(false);
        }
      }}
    >
      <EmployeePicture employee={employee} />
      <p>
        {employee.firstName} {employee.lastName}
      </p>
      {editing && placed && (
        <Icon
          className="go-to-employee-icon"
          icon={faArrowRight}
          title="Go to employee"
        />
      )}
    </div>
  );
};

export default ListEmployee;
