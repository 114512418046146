import React, { useState } from "react";

import { Button, Modal, Input } from "@intility/bifrost-react";
import { useUser } from "@intility/react-msal-browser";

import { useSave } from "contexts/saveContext";
import "../../styles/css/dashboard.css";

const RenameDraftModal = ({ open, setOpen, save }) => {
  const { saves, updateBasicSave } = useSave();
  const user = useUser();

  const [nameExists, setNameExists] = useState(false);
  const [draftName, setDraftName] = useState(save?.name);

  // Clicking create draft button
  const handleUpdateDraft = () => {
    const exists = saves.find(
      (save) => save.name.toLowerCase() === draftName.toLowerCase()
    );
    if (exists) {
      setNameExists(true);
      return;
    }
    const id = user?.homeAccountId.split(".")[0];
    // New current save object
    const updatedSave = {
      ...save,
      name: draftName,
      lastEditedByEmployeeId: id,
    };

    updateBasicSave(updatedSave);
    setOpen(false);
  };

  return (
    <Modal
      className="add-new-draft-modal"
      header="Rename draft"
      isOpen={open}
      onRequestClose={() => setOpen(false)}
    >
      <Input
        label="Project Name"
        placeholder="Eks. My draft"
        value={draftName ?? ""}
        autoFocus
        required
        onChange={(e) => {
          setDraftName(e.target.value);
          setNameExists(false);
        }}
        {...(nameExists &&
          draftName && {
            state: "alert",
            feedback: "Draft name already exists",
          })}
      />
      <Modal.Footer>
        <div className="buttons-container">
          <Button
            onClick={() => {
              setDraftName(null);
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            {...((!draftName || (draftName && nameExists)) && {
              state: "inactive",
            })}
            variant="filled"
            onClick={() => draftName && handleUpdateDraft()}
          >
            Rename
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameDraftModal;
