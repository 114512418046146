import React, { useState } from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { Modal, Button, Input, Icon } from "@intility/bifrost-react";

import "../styles/css/modal.css";
import { useSave } from "contexts/saveContext";

const DummyEmployee = ({
  desk,
  updateDesk,
  showEditDummyModal,
  setShowEditDummyModal,
  setDraggingDummyEmployee,
  lockEmployees,
}) => {
  const { editing } = useSave();
  const [text, setText] = useState(
    desk.dummyEmployee ? desk.dummyEmployee : ""
  );

  const getEditModal = () => {
    return (
      <Modal
        header={
          desk.dummyEmployee ? "Edit dummy employee" : "Add dummy employee"
        }
        isOpen={showEditDummyModal}
        onRequestClose={() => cancelEditDummyEmployee()}
      >
        <Input
          label="Dummy Employee"
          className="dummy-employee-input"
          placeholder="Ex. Student"
          value={text}
          onChange={(e) => {
            if (e.target.value.length <= 20) {
              setText(e.target.value);
            }
          }}
          description="Must be less than 20 characters"
          autoFocus
        />
        <Modal.Footer>
          <div className="modal-btns-container">
            <Button
              onClick={() => {
                cancelEditDummyEmployee();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              disabled={!desk.dummyEmployee && !text}
              onClick={() => {
                saveBtnClick();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const cancelEditDummyEmployee = () => {
    setShowEditDummyModal(false);
    setText(desk.dummyEmployee ? desk.dummyEmployee : "");
  };

  const saveBtnClick = () => {
    if (!text) {
      deleteDummyEmployee();
    } else {
      saveDummyEmployee();
    }
    setShowEditDummyModal(false);
  };

  const saveDummyEmployee = () => {
    if (!text) {
      deleteDummyEmployee();
    } else {
      let changedDesk = desk;
      changedDesk.dummyEmployee = text;
      updateDesk(changedDesk);
    }
  };

  const deleteDummyEmployee = () => {
    let changedDesk = desk;
    delete changedDesk.dummyEmployee;
    updateDesk(changedDesk);
  };

  const onDragStart = (e) => {
    if (editing) {
      setDraggingDummyEmployee(true);
      e.dataTransfer.setData("currentDesk", JSON.stringify(desk));
    }
  };

  const getDummyEmployee = () => {
    return (
      <>
        <p
          className="dummy-employee"
          draggable={editing && !lockEmployees}
          onDragStart={(e) => {
            onDragStart(e);
          }}
          onDragEnd={() => {
            setDraggingDummyEmployee(false);
          }}
        >
          {desk.dummyEmployee ? desk.dummyEmployee : ""}
        </p>
        {editing && (
          <Icon
            icon={faPencil}
            className="desk-icon dummy-employee-edit-icon"
            onClick={() => setShowEditDummyModal(true)}
          />
        )}
      </>
    );
  };

  return (
    <>
      {getDummyEmployee()}
      {showEditDummyModal && getEditModal()}
    </>
  );
};

export default React.memo(DummyEmployee);
