import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Spinner } from "@intility/bifrost-react";
import useSWR from "swr";

import ErrorMessage from "components/errorMessage";
import { departmentColors } from "../data/deparmentColors";
import { useHistory } from "react-router-dom";

const EmployeeContext = createContext(null);

const useEmployee = () => useContext(EmployeeContext);

const EmployeeProvider = ({ children }) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  // Fetching employees
  const { data: employees, error: employeesError } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/employees`,
    {
      revalidateOnFocus: false,
    }
  );

  // Create departmentlist and assign colors based on employee departments
  let departments = useMemo(() => {
    if (!employees) return;
    return Array.from(
      employees
        .reduce((map, e) => {
          if (e.department && !map.has(e.department)) {
            let color = departmentColors[map.size % departmentColors.length];
            map.set(e.department, { name: e.department, color: color });
          }
          return map;
        }, new Map())
        .values()
    );
  }, [employees]);

  const employeeIds = useMemo(() => {
    if (!employees) return;
    return Array.from(employees, (employee) => employee.id);
  }, [employees]);

  useEffect(() => {
    if (!employees) return;
    if (employees.length > 0) {
      setError(false);
    }
  }, [employees]);

  const getEmployee = (employeeId) => {
    let employee = employees.find((x) => x.id === employeeId);
    return employee;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!employees && employeesError) {
        setError(true);
      }
    }, 10000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ErrorMessage error={employeesError} />;
  }

  if (!history.location.pathname.includes("dashboard") && !employees) {
    return (
      <div className="before-load-spinner">
        <Spinner label="Loading employees..." />
      </div>
    );
  }

  return (
    <EmployeeContext.Provider
      value={{ employees, departments, getEmployee, employeeIds }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export { useEmployee };
export default EmployeeProvider;
