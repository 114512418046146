import React, { useEffect, useState } from "react";

import {
  faChairOffice,
  faEllipsisH,
  faLockAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { Badge, Dropdown, Icon, Skeleton } from "@intility/bifrost-react";

import { useEmployee } from "contexts/employeeContext";
import { useSave } from "contexts/saveContext";
import DeleteDraftModal from "./deleteDraftModal";
import RenameDraftModal from "./renameDraftModal";
import DraftDropdown from "./draftDropdown";
import UnlockDraftModal from "./unlockDraftModal";
import "../../styles/css/dashboard.css";

const Draft = ({ save }) => {
  const { employees } = useEmployee();
  const { openDraft } = useSave();

  const [showDropdown, setShowDropdown] = useState(false);
  const [deleteDraftModalOpen, setDeleteDraftModalOpen] = useState(false);
  const [renameDraftModalOpen, setRenameDraftModalOpen] = useState(false);
  const [unlockDraftModalOpen, setUnlockDraftModalOpen] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [dateCreated, setDateCreated] = useState();
  const [dateModified, setDateModified] = useState();
  const [timeEdited, setTimeEdited] = useState();

  // Setting created by amd created date
  useEffect(() => {
    if (!save || !employees) return;
    getCreatedBy();
    getModifiedDate();
    getTimeEdited();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save, employees]);

  const getCreatedBy = () => {
    const createdByEmp = employees.find(
      (emp) => emp.id === save.createdByEmployeeId
    );
    if (createdByEmp) {
      setCreatedBy(`${createdByEmp.firstName} ${createdByEmp.lastName}`);
    }
    const date = new Date(save.created);
    const d = date.getDate();
    const m = date.getMonth() + 1;
    setDateCreated(
      `${d < 10 ? "0" + d : d}.${m < 10 ? "0" + m : m}.${date.getFullYear()}`
    );
  };

  // Set modified by employee and date
  const getModifiedDate = () => {
    if (!save?.modified) return;
    const time = getTime(save?.modified);
    setDateModified(time);
  };

  const getTimeEdited = () => {
    if (!save?.timeSetToBeingEdited) return;
    const time = getTime(save?.timeSetToBeingEdited);
    setTimeEdited(time);
  };

  const getTime = (saveDate) => {
    const date = new Date(saveDate);
    const d = date.getDate();
    const m = date.getMonth() + 1;

    const currDate = new Date();
    const timezone = currDate.getTimezoneOffset();
    const diff = currDate - date;

    //under a day ago
    if (diff < 86400000) {
      let hours = Math.floor(diff / 3600000) + timezone / 60;
      if (hours < 1) {
        let mins = Math.floor(diff / 60000) + timezone;
        if (mins === 0) {
          return " just now";
        } else if (mins === 1) {
          return mins + " minute ago";
        } else {
          return mins + " minutes ago";
        }
      } else {
        if (hours === 1) {
          return hours + " hour ago";
        }
        return hours + " hours ago";
      }
    } else if (diff > 86400000 && diff < 86400000 * 2) {
      return "1 day ago";
    } else if (diff > 86400000 * 2 && diff < 86400000 * 3) {
      return "2 days ago";
    } else if (diff > 86400000 * 3 && diff < 86400000 * 4) {
      return "3 days ago";
    } else {
      return `${d < 10 ? "0" + d : d}.${
        m < 10 ? "0" + m : m
      }.${date.getFullYear()}`;
    }
  };

  // Clicking enter or space opens focused title
  const handleKeyPressOnDraft = (e) => {
    if (!e.target.classList.contains("draft-title")) return;
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      openDraft(save);
    }
  };

  // Clicking enter or space opens focused dropdown icon
  const handleKeyPressOnDropdown = (e) => {
    if (!e.target.classList.contains("draft-dropdown-icon")) return;
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      setShowDropdown((x) => !x);
    }
  };

  // Checking if a draft is being edited
  useEffect(() => {
    if (!save || !employees) return;
    if (save.beingEdited) {
      const emp = employees.find(
        (x) => x.id === save.currentlyEditingEmployeeId
      );
      if (!emp) return;
      setEditingEmployee(`${emp.firstName} ${emp.lastName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  return (
    <>
      <div className="draft-container bfl-padding" id={save.id}>
        <div className="draft-content-container">
          <div className="draft-content">
            <div>
              <p
                className="draft-title"
                tabIndex="0"
                onKeyDown={(e) => handleKeyPressOnDraft(e)}
                onClick={() => openDraft(save)}
              >
                {save.name}
              </p>
              {!dateModified && (
                <p style={{ margin: "6px 0px", width: "100%" }}>
                  <Skeleton>
                    <Skeleton.Text width="60%" />
                  </Skeleton>
                </p>
              )}
              {createdBy && dateModified && (
                <div className="draft-created-edited">
                  <p className="">{`Edited ${dateModified}`}</p>
                </div>
              )}
              {save.noOfDesks >= 0 && save.noOfEmptyDesks >= 0 && (
                <div className="drafts-empty-seats">
                  <Icon icon={faChairOffice} />
                  <p>
                    {save.noOfEmptyDesks}/{save.noOfDesks} seats empty
                  </p>
                </div>
              )}
            </div>
            {save.publicSave && <Badge> Public map </Badge>}
            {save.beingEdited && editingEmployee && (
              <Badge state="warning" className="editing-badge">
                <Icon icon={faLockAlt} />
                {editingEmployee} started editing {timeEdited}
              </Badge>
            )}
          </div>

          <Dropdown
            content={
              <DraftDropdown
                save={save}
                setShowDropdown={setShowDropdown}
                setRenameDraftModalOpen={setRenameDraftModalOpen}
                setDeleteDraftModalOpen={setDeleteDraftModalOpen}
                setUnlockDraftModalOpen={setUnlockDraftModalOpen}
              />
            }
            placement="bottom-end"
            visible={showDropdown}
            onClickOutside={() => setShowDropdown(false)}
          >
            <Icon
              className="draft-dropdown-icon"
              icon={faEllipsisH}
              onClick={() => setShowDropdown((x) => !x)}
              tabIndex="0"
              onKeyDown={(e) => handleKeyPressOnDropdown(e)}
            />
          </Dropdown>
        </div>

        <div className="draft-footer">
          {!createdBy && (
            <p>
              <Skeleton>
                <Skeleton.Text width="85%" />
              </Skeleton>
            </p>
          )}
          {createdBy && <p>{`Created by ${createdBy}, ${dateCreated}`}</p>}
        </div>
      </div>

      {deleteDraftModalOpen && (
        <DeleteDraftModal
          open={deleteDraftModalOpen}
          setOpen={setDeleteDraftModalOpen}
          save={save}
        />
      )}

      {renameDraftModalOpen && (
        <RenameDraftModal
          open={renameDraftModalOpen}
          setOpen={setRenameDraftModalOpen}
          save={save}
        />
      )}

      {unlockDraftModalOpen && (
        <UnlockDraftModal
          open={unlockDraftModalOpen}
          setOpen={setUnlockDraftModalOpen}
          save={save}
          editingEmployee={editingEmployee}
          timeEdited={timeEdited}
        />
      )}
    </>
  );
};

export default Draft;
